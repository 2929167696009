.table-card {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.color-white {
  color: #f8f8f8;
}
.color-calipso {
  color: #3bcddc !important;
}

.table-card-fullw {
  width: 95% !important;
}

.table-card-fullw thead,
.table-card-fullw thead tr {
  width: 95% !important;
}

.progressTextPorcent {
  font-size: 3em;
  font-weight: 800;
  color: #3f3f3f;
  z-index: 2;
  background-color: #f8f8f8;
  padding: 3px;
}
.progressTextPorcentBig {
  font-size: 6em;
  font-weight: 600;
  color: #3f3f3f;
}
.progressTextresalte {
  font-size: 1em;
  line-height: 1.5;
  font-weight: 600;
  color: #3f3f3f;
}
.minimap {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  background-color: rgb(189, 189, 189);
}

.card-route {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  margin-right: 21px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 5px;
  -webkit-box-shadow: 10px 8px 5px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 8px 5px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 8px 5px -6px rgba(0, 0, 0, 0.75);
}

.container-metric {
  text-align: right;
  color: #0c0b0b;
  font-size: 16px;
  background-color: #fff;
  padding-top: 14px;
  padding-bottom: 3px;
  min-height: 50px;
  min-width: 50px;
}

.alert.alert-danger.relevanText {
  font-size: 16px !important;
  color: red;
  font-weight: 500;
}

.card-body-flex {
  min-height: 1px;
  padding: 1.25rem;
  display: flex;
}
.table-card-fullw-50 {
  margin-bottom: 1rem;
  color: #212529;
  width: 65% !important;
}
.table-card-fullw-20 {
  margin-bottom: 1rem;
  color: #212529;
  width: 30% !important;
  height: fit-content;
}
.vertical-align-initial {
  vertical-align: initial;
}

.tr-boder-solid {
  border: 1px solid gainsboro;
  max-height: 50px !important;
  padding: 10px;
}
.card-content-item {
  box-sizing: inherit;
  padding: 5px;
}
