.popInfo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.popInfo:after {
  content: "";
  position: absolute;
  bottom: -10px; /* tamaño de la flecha */
  left: 0;
  border-width: 10px 10px 0; /* crea un triángulo */
  border-style: solid;
  border-color: white transparent transparent transparent; /* color de la flecha (igual que el fondo del popup) */
}

.popInfoTile {
  font-size: 11px;
  color: #333;
  font-weight: 600;
}
.popInfotext {
  font-size: 10px;
  color: #333;
}
.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 12px;
  color: red;
  cursor: pointer;
}

.closeButton:focus {
  outline: none;
}
