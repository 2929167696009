.DashIconsResumencardDistach {
  padding: 3px;
  min-width: 18% !important;
}
.DashIconsResumencardDistach-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}
.dashIcon {
  font-size: 3em;
  color: blue;
}
.dashIconDispachs {
  font-size: 3em !important;
  color: blue;
}
.dashIconsresumencardcontent {
  text-align: center;
  padding: 3px !important;
}
.dashicontitle {
  padding: 3px;
  margin: 3px;
}

.dashIcon-blue {
  color: blue;
}
.dashIcon-grey {
  color: rgb(158, 158, 158);
}
.dashIcon-green {
  color: rgb(173, 231, 173);
}
.dashIcon-on-line {
  color: rgb(17, 240, 103);
}
.dashIcon-red {
  color: rgb(240, 50, 17);
}

.dashicontspansmalltext {
  padding: 1px;
  font-size: 0.7em;
}

.positionCants {
  margin-top: 10px;
  padding: 1px;
  font-size: 3em;
  display: inline-block;
}
