.usa-checkbox__input--tile:checked + [class*="__label"],
.usa-radio__input--tile:checked + [class*="__label"] {
  background-color: #f8f8f8;
  border-left: 5px solid #591e8f;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
}
.radio__input--tile + [class*="__label"] {
  background-color: rgb(226, 219, 219);
  border: 2px solid rgba(27, 27, 27, 0.3);
  color: #1b1b1b;
  border-radius: 0.25rem;
  min-height: 80px;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
}
.radio__label {
  cursor: pointer;
  display: inherit;
  font-weight: 400;
  margin-top: 0.75rem;
  padding-left: 2rem;
  position: relative;
}

.usa-checkbox__label,
.usa-radio__label {
  cursor: pointer;
  display: inherit;
  font-weight: 400;
  min-height: 80px;
  margin-top: 0.75rem;
  padding-left: 2rem;
  position: relative;
}
.usa-checkbox__label,
.usa-radio__label {
  color: #1b1b1b;
}

.usa-checkbox__input--tile + [class*="__label"],
.usa-radio__input--tile + [class*="__label"] {
  background-color: #fff;
  border: 1px solid rgba(27, 27, 27, 0.3);
  color: #1b1b1b;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
}
.usa-checkbox__label,
.usa-radio__label {
  cursor: pointer;
  display: inherit;
  font-weight: 400;
  margin-top: 0.75rem;
  padding-left: 2rem;
  position: relative;
  padding-top: 28px;
}
.usa-checkbox__label,
.usa-radio__label {
  color: #1b1b1b;
}
.usa-fieldset {
  padding: 0px !important;
}
.usa-checkbox__label,
.usa-combo-box__input,
.usa-combo-box__list,
.usa-fieldset,
.usa-hint,
.usa-input,
.usa-input-group,
.usa-radio__label,
.usa-range,
.usa-select,
.usa-textarea {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 1.06rem;
  line-height: 1.3;
}
.usa-radio__input:checked + [class*="__label"]::before {
  box-shadow: 0 0 0 2px #591e8f, inset 0 0 0 2px #fff;
}
.usa-radio__input:checked + [class*="__label"]::before {
  background-color: #591e8f;
}

.usa-checkbox__input--tile + [class*="__label"]:before,
.usa-radio__input--tile + [class*="__label"]:before {
  left: 0.5rem;
}
.usa-checkbox__label:before,
.usa-radio__label:before {
  content: " ";
  display: block;
  left: 0;
  margin-left: 2px;
  margin-top: 0.064rem;
  position: absolute;
}
.usa-radio__input {
  position: absolute;
  left: -999em;
  right: auto;
}
.usa-radio__label::before {
  height: 1.25rem;
  border-radius: 99rem;
  width: 1.25rem;
}

.radio__label {
  cursor: pointer;
  display: inherit;
  font-weight: 400;
  margin-top: 0.75rem;
  padding-left: 2rem;
  position: relative;
  color: #1b1b1b;
}
.usa-radio__input--tile + [class*="__label"]:before {
  left: 0.5rem;
  background: #fff;
  box-shadow: 0 0 0 2px #1b1b1b;
}
.usa-radio__input--tile + [class*="__label"] {
  padding: 25px 0 1rem 2.5rem;
}
