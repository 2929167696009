.typeMiniformsBarsMetricsHistory {
  background-color: #ffffff;
  color: #121828;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  overflow: hidden;
  align-items: lef;
  display: inline-block;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  margin: 5px;
  padding: 3px;
  max-height: 230px;
  height: 230px;
  width: auto;
}
.typeMiniformsGrafslineMonth {
  background-color: #ffffff;
  color: #121828;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  overflow: hidden;
  align-items: lef;
  display: inline-block;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  margin: 5px;
  padding: 3px;
  max-height: 230px;
  padding: 3px;
  max-height: 230px;
  height: 230px;
  width: auto;
}
