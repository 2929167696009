@import "./index.scss";

body {
  background-color: #f8f8f8;
  color: #591e8f;
  font-size: 14px;
}
h2 {
  color: #591e8f;
}
.h2-withe {
  color: #f0f8ff;
}
div.span {
  color: #591e8f;
  font-size: 18px;
}
.customStyleFade {
  max-width: 300px;
}
#firstmensaje {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 400;
  text-decoration: wavy;
  font-style: italic;
}
#ajustefrom {
  max-width: 650px !important;
}
#ajustefromIni {
  max-width: 350px !important;
  text-align: left;
}
#bannerServicios {
  width: 100%;
}
#selectorBody {
  min-height: 500px;
}
#logomaps {
  max-width: 50px;
  max-width: 50px;
  padding: 1px;
  border-radius: 100%;
}
#btn-locale {
  max-width: 50px;
  width: 20px;
  display: inline-block;
}
#input-ebiex-maps {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-box-ebiex {
  display: block;
  width: 100% !important;
  padding: 6px !important;
}
#imputSearchFromOrigen {
  display: block;
  width: 100% !important;
  padding: 20px !important;
  font-size: 1em !important;
  line-height: 1.5;
  color: #030507;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DisplayBtnMaps {
  display: inline;
}

.my-custom-label-ping {
  color: #f8f8f8; /* Color del texto */
  font-size: 10px; /* Tamaño del texto */
  font-weight: 600; /* Grosor del texto */
  background-color: red; /* Fondo del texto */
  padding: 5px; /* Espaciado alrededor del texto */
  border-radius: 8px;
  margin-right: -10px;
  top: 5px;
  position: absolute;
}

.dropdown-ebiex {
  z-index: 25;
  background-color: #fff;
  width: max-content;
}
.comboboxList-ebiex {
  padding-top: 50px;
  width: max-content;
}

// div.bodycontainer {
//   margin-left: auto !important;
//   margin-right: auto !important;
//   margin-top: 0 !important;
//   padding-top: 6em !important;
//   padding-bottom: 10% !important;
//   margin-left: auto;
//   margin-right: auto;
//   background-color: #fff;
// }
div.bodycontainer {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 0 !important;
  background-color: #fff;
  overflow-y: scroll;
  width: 100% !important;
  height: 100%;
  background-color: #fff;
  left: 0px;
  right: 0px;
  margin-left: 14%;
  overflow-y: scroll;
  overflow-y: scroll;
  width: 100%;
  text-align: left;
  overflow-y: scroll;
  position: fixed;
  min-height: 55%;
  bottom: 0px;
  padding-top: 6em;
}

div.ui.fluid.container.topcontainer {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: $blanco;
  z-index: 33 !important;
  top: 0px;
  height: 5em;
  padding-top: 5px;
  position: fixed;
  // box-shadow: 0 -10px 10px 15px rgba(0,0,0,0.5);
  // -webkit-box-shadow: 0 -10px 10px 15px rgba(0,0,0,0.5);
  // -moz-box-shadow: 0 -10px 10px 15px rgba(0,0,0,0.5);
  z-index: 1;
}

.bodycontent {
  background-color: $blanco;
  top: 0px;

  min-height: 1140px;
  max-width: 1140px !important;

  // box-shadow: -1px 2px 5px 0px rgba(139, 47, 189, 0.75);
  // -webkit-box-shadow: -1px 2px 5px 0px rgba(136, 51, 201, 0.75);
  // -moz-box-shadow: -1px 2px 5px 0px rgba(159, 39, 189, 0.75);
}
.bodycontainerbx {
  background-color: #fff;
}
.bicci-btn-hover:hover {
  fill: #fff !important;
  color: #fff !important;
  background-color: #00cdf6 !important;
}
.topbar {
  max-width: 100%;
}
.formcontainer {
  background-color: rgb(255, 255, 255);
  padding: 20px 20px 20px 20px;
  min-height: 350px;
  max-height: 650px;
  margin-bottom: 50px;
  box-shadow: -1px 2px 5px 0px rgba(139, 47, 189, 0.75);
  -webkit-box-shadow: -1px 2px 5px 0px rgba(136, 51, 201, 0.75);
  -moz-box-shadow: -1px 2px 5px 0px rgba(159, 39, 189, 0.75);
  border-radius: 5px;
  text-align: left;
}

.formcontainerr {
  background-color: rgb(255, 255, 255);
  padding: 20px 20px 20px 20px;
  min-height: 350px;
  margin-bottom: 50px;
  box-shadow: -1px 2px 5px 0px rgba(139, 47, 189, 0.75);
  -webkit-box-shadow: -1px 2px 5px 0px rgba(136, 51, 201, 0.75);
  -moz-box-shadow: -1px 2px 5px 0px rgba(159, 39, 189, 0.75);
  border-radius: 5px;
  text-align: left;
}

.btn-fullCover {
  width: 100% !important;
  margin: 0px !important;
}

.btn-ebiex {
  background-color: #591e8f;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  outline: none;
  padding: 10px;
  min-width: 150px;
  margin: 0 0 1em;
}
.btn-ebiex:hover {
  background: #0ed0e6 !important;
  border: none;
  border-radius: 3px;
  color: #7a18d3;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  outline: none;
  padding: 10px;
  min-width: 150px;
  margin: 0 0 1em;
}
.btn-ebiex-dark {
  background-color: #fff;
  border: none;
  border-radius: 3px;
  color: #591e8f;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  outline: none;
  padding: 10px;
  min-width: 150px;
  margin: 0 0 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.btn-ebiex-dark:hover {
  background-color: #d6b3f5;
  border: none;
  border-radius: 3px;
  color: #591e8f;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  outline: none;
  padding: 10px;
  min-width: 150px;
  margin: 0 0 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.btn-add-guide:hover {
  padding: 10px;
  background: #0ed0e6 !important;
  display: inline-block !important;
  padding: 8px 35px 10px 35px !important;
  border-radius: 4px !important;
  color: #7a18d3;
  font-size: 15px;
  font-weight: 600;
  margin: 1px;
  line-height: 1.5;
}
#bodyFroms {
  max-width: 650px;
  width: 550px;
  margin-bottom: 200px;
  max-width: 650px;

  /* padding: 20px; */
  padding-bottom: 20px;
}
#brandsLogo {
  text-align: -webkit-center !important;
  text-align: center !important;
}
.brands {
  max-width: 100px !important;
}
#exact001 {
  color: #fff !important;
}
#mapControl {
  -webkit-box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  padding: 20px;
}
#mapsControl {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  z-index: 3;
  min-width: 350px;
  -webkit-box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  max-width: 100% !important;
  height: Auto !important;
}

.formcontent {
  background-color: aliceblue;
  min-height: 350px;
}

.btn-nostyle {
  max-width: 49%;
  text-align: left;
  position: relative;
  display: inline;
  padding-left: 0px;
  text-decoration: underline;
  font-size: 11px;
  font-weight: 60px;
  color: #591e8f;
  :hover {
    color: aqua;
  }
}
.noshowdesktop {
  display: none;
}
.nevershow {
  display: none;
}

.testcomponenbodynav {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  background-color: rgb(245 245 245) !important;
}
.componenbodyservicioscard {
  min-height: 250px !important;
}
.componenbodyservicios {
  width: 100% !important;
  height: 100% !important;
  margin: 5px !important;
  padding: 10px !important;
  // min-width: 400px;
  // min-height: 400px;
  background-color: rgb(245 245 245) !important;
}
.testcomponenbody {
  width: 100%;
  height: 100%;
  // margin: 5px;
  // -webkit-box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  // box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  // min-width: 400px;
  // min-height: 400px;
  background-color: rgb(237 237 237);

  // padding-left: 3px;
  // padding-right: 3px;
  padding-top: 0%;
}
.DashboardNavbarrow {
  height: 100%;
}

.navbarow-dash {
  justify-content: end !important;
}
.navbarow-dash-right {
  display: inline-block;
}
.tabletheadrotate {
  //transform: rotate(269deg);
  border: 1px solid black;
  max-width: 50px;
  height: 180px;
}
.tableoverflow {
  overflow: auto !important;
}
.dashComponenBody {
  justify-content: center !important;
}
#DashboardNavbarrow {
  height: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
#testScroll1.testcomponensidebar {
  min-width: 5% !important;
  width: 60px;
}

#testScroll1.testcomponensidebar.expanded {
  overflow-y: scroll;
  max-height: 100vh;
  width: 341px;
  min-width: 14% !important;
  padding: 3px;
  text-align: left;
  background-color: whitesmoke;
  border: 0.5px solid black;
  overflow-y: scroll;
  background-color: #1e2158;
  min-height: 100vh;
  left: 35px;
  top: 4.8em;
  bottom: 0px;
  padding-bottom: 54px;
  padding-top: 5px;
  border-top-left-radius: 8px;
}

.MuiListItem-root {
  transition: all 03s;
}

.markertsbransLogos {
  max-width: 35px !important;
  max-height: auto !important;
  padding: 5px;
}

#testScroll2.testcomponen {
  width: 100% !important;
  max-width: 100% !important;
  /* height: 100vh; */
  /* min-height: 55%; */
  background-color: #fff;
  left: 0px;
  right: 0px;
  padding-bottom: 1%;
  overflow-y: scroll;
  overflow-y: scroll;
  text-align: left;
  background-color: whitesmoke;
  border: 0.5px solid black;
  overflow-y: scroll;
  background-color: #1e2158;
  /* top: 4.8em; */
  /* left: 0px; */
  /* bottom: 0px; */
  /* padding-top: 27%;
  width: 100% !important;
  max-width: 100% !important;
  /* height: 100vh; */
  /* min-height: 55%; */
  /* background-color: #1e2158; */
  /* top: 4.8em; */
  /* left: 0px; */
  /* bottom: 0px; */
  /* padding-top: 27%; */
}

.testcomponent {
  min-width: 300px;
  min-height: 150px;
  margin: 5px;
  padding: 1px;
  background-color: rgb(162, 199, 146);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.DashIconsResumenDispachs-container {
  display: flex;
  flex-wrap: wrap !important;
  justify-content: center;
  flex: 0 0 100%;
}
.DashIconsResumencardDistachElements {
  width: 150px;
}

#dash-container {
  padding: 0px;
  margin: 0px;
  .dash-container-colmun {
    overflow-y: scroll;
    max-height: 100vh;
    width: 100%;
    padding: 0px !important;
    margin: auto !important;
  }
}
.sidebar-body {
  background-color: rgb(30, 33, 88);
  padding: 20px;

  hr {
    margin: 0px;
  }
  .sidebar-hr {
    background-color: rgba(209, 209, 209, 0.51);
  }
}
.brands-dash {
  background-color: #fff !important;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: 70px !important;
  margin-bottom: 20px;
}
.btn-sidebarLink {
  display: block;
  width: 100%;
  height: 100%;
  padding: 3px;
  text-align: inherit;
  vertical-align: middle;
  color: rgb(255, 255, 255);
  :hover {
    color: chartreuse;
    background-color: rgba(95, 95, 108, 0.578);
  }
  :focus {
    background-color: rgba(95, 95, 108, 0.578);
    color: chartreuse;
  }
  :active {
    background-color: rgba(95, 95, 108, 0.578);
    color: chartreuse;
  }
}
.sidebarLink-active {
  background-color: rgba(95, 95, 108, 0.578) !important;
  color: chartreuse !important;
}
.btn-sidebar {
  width: 100%;
  height: 100%;
  text-align: inherit;
  vertical-align: middle;
  color: rgb(255, 255, 255);
  :hover {
    color: chartreuse;
  }
  :focus {
    color: chartreuse;
  }
}
.btn-title {
  padding: 5px;
  font-size: 0.8em !important;
}

.colorchartreuse {
  color: chartreuse;
}
.colorred {
  color: red;
}

#hiddenmax980px {
  display: none;
}
#expanlayout {
  display: unset;
}
#expanlayoutMenu {
  display: unset;
  position: fixed;
  z-index: 1000;
}

input#imagen {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for="imagen"] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 15px 20px !important;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}
input#imagen2 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for="imagen2"] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 15px 20px !important;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}
input#imagen3 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for="imagen3"] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 15px 20px !important;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}
input#imagen4 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for="imagen4"] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 15px 20px !important;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}
input#imagen5 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for="imagen5"] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 15px 20px !important;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}

input#imagen6 {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for="imagen6"] {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  padding: 15px 20px !important;
  width: 100%;
  text-align: center;
  border-radius: 3px;
}

.minimalsize {
  font-size: 10px;
  width: 100%;
}
.develop-style {
  background-color: #52a0ee;
  border: 0.5px solid;
}
.topalerts {
  width: 100%;
  margin: 5px;
}

.css-w563kw-MuiCardHeader-root {
  padding: 10px 24px !important;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}
.ui.form .field .ui.input input[disabled],
.ui.form .fields .field .ui.input input[disabled] {
  background-color: aliceblue;
  color: #000;
}
.mantenimiento {
  margin-left: 4%;
}
.mantenimiento #imgmante {
  max-width: 450px;
  width: fit-content;
}
//esconder menu lateral
#testScroll1.landScreend-testScroll1 {
  display: none;
}
#testScroll2.landScreend-testScroll2 {
  // width: 100% !important;
  // margin-left: auto;
  // padding-bottom: 50px;
  width: cal(85% -35px) !important;
  max-width: cal(80% -35px) !important;
  height: 88%;
  min-height: 75%;
  background-color: #fff;
  right: 0px;
  //margin-left: 35px;
  padding-bottom: 10%;
  overflow-y: scroll;
  overflow-y: scroll;
  padding: 3px;
  text-align: left;
  background-color: whitesmoke;
  border: 0.5px solid #fff;
  border-radius: 8px;
  overflow-y: scroll;
  min-width: 14% !important;
  background-color: #1e2158;
  position: fixed;
  top: 4.8em;
  left: 35px;
  bottom: 0px;
  /* padding-top: 27%; */
}

.menuScreend-testScroll1 {
  display: unset;
}
.menuScreend-testScroll2 {
  width: 100% !important;
  margin-left: 15%;
}
// esconder menu superior
.landScreend-topcontainer {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: #fff;
  z-index: 33 !important;
  top: 0px;
  height: 5em;
  padding-top: 5px;
  position: fixed;
  z-index: 1;
  display: none !important;
}
.landScreend-bodycontainer {
  padding-top: 0px !important;
}
.landScreend-testcomponensidebar {
  padding-top: 125px;
}
.menuScreend-topcontainer {
  display: unset;
}
.menuScreend-bodycontainer {
  padding-top: 6em !important;
}
.menuScreend-testcomponensidebar {
  padding-top: 125px;
}
.alignItemscentertitle {
  text-align: center !important;
}

.noRed {
  text-align: center;
  width: 100%;
  // z-index: 9000;
  // position: fixed;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // top: 0px;
  color: red;
}
.onLine {
  text-align: center;
  width: 100%;
  color: rgb(0, 255, 13);
  //z-index: 9000;
  // position: fixed;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // top: 0px;
}
.button-container {
  align-items: center;
  display: flex;
}

.listNone {
  list-style: none;
}
.padding-left-0 {
  padding-left: 0px;
}

@media only screen and (max-width: 950px) {
  .ui.celled.grid > .column:not(.row),
  .ui.celled.grid > .row > .column {
    padding: 0px;
  }
  #hiddenmax980px {
    display: unset;
  }
  #hiddenmax980px {
    display: none;
  }
  #expanlayoutMenu {
    display: none;
  }

  .noshowmovil {
    display: none;
  }
  .showmovil {
    display: inline;
  }

  #micuentafromIni {
    width: 100%;
    padding-left: 1px !important;
    padding-right: 1px !important;
    min-height: auto;
  }
  .DashIconsResumencardDistachElements {
    width: 90% !important;
  }
  // div.bodycontainer {
  //   margin-left: auto !important;
  //   margin-right: auto !important;
  //   padding-top: 90px;
  //   background-color: #f8f8f8;
  //   margin-bottom: 70px;
  //   height: 100%;
  //   min-height: 100%;
  // }
  div.bodycontainer {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 0 !important;
    background-color: #fff;
    overflow-y: scroll;
    width: 100% !important;
    height: 100%;
    padding: 0px;
    background-color: #fff;
    left: 0px;
    right: 0px;
    margin-left: 14%;
    overflow-y: scroll;
    overflow-y: scroll;
    width: 100%;
    padding-top: 6em;
    text-align: left;
    overflow-y: scroll;
    position: fixed;
    min-height: 55%;
    bottom: 0px;
  }

  .testcomponenbody {
    height: 100%;
    width: 100%;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
    background-color: #ededed;
    padding-bottom: 4%;
    padding-top: 0%;
    margin: 0px;
  }
  .ui.grid > .row {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding: 0;
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }

  #testScroll1.testcomponensidebar {
    min-width: 5% !important;
    width: 60px;
  }

  #testScroll1.testcomponensidebar.expanded {
    overflow-y: scroll;
    width: 100%;
    min-height: auto;
    padding: 10px;
    text-align: left;
    background-color: whitesmoke;
    border: 0.5px solid black;
    overflow-y: scroll;
    min-width: 14% !important;
    background-color: #1e2158;
    position: relative;
    left: 35px;
    bottom: 0px;
    padding-bottom: 54px;
    padding-top: 10px;
    display: contents;
    border-radius: 8px;
  }

  .MuiListItem-root {
    transition: all 0.3s;
  }

  #testScroll2.testcomponen {
    width: 100% !important;
    height: 100vh;
    margin: 0px;
    left: 0px;
    right: 0px;
    text-align: center !important;
    border: 0.5px solid black;
    overflow-y: scroll;
    min-width: 15% !important;
    max-width: 100% !important;
    background-color: #1e2158;
    display: block;
    min-height: 55%;
    bottom: 0px;
    position: relative;
    top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 2%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .testcomponent {
    min-width: 300px;
    min-height: 150px;
    margin: 5px;
    padding: 1px;
    background-color: rgb(162, 199, 146);
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-left: 0px;
    padding-right: 0px;
  }

  #dash-container {
    width: 100%;
    padding: 0px;
    margin: 0px;
    .dash-container-colmun {
      overflow-y: scroll;
      max-height: 100vh;
      width: 100% !important;
      padding: 0px !important;
      margin: auto !important;
    }
  }
  .sidebar-body {
    background-color: rgb(30, 33, 88);
    padding: 20px;
    width: 100% !important;
    hr {
      margin: 0px;
    }
    .sidebar-hr {
      background-color: rgba(209, 209, 209, 0.51);
    }
  }

  div.ui.fluid.container.topcontainer {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    position: fixed;
    z-index: 33 !important;
    top: 0px;
    height: 80px;
    padding-top: 1em;
    box-shadow: 0 -10px 10px 15px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 -10px 10px 15px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 -10px 10px 15px rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .ui.celled.grid > .column:not(.row),
  .ui.celled.grid > .row > .column {
    box-shadow: 0px 0px 0px 0px #ffffff00;
  }

  .adressContainer {
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 10%;
  }
  .typecardadress {
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 11px !important;
    bottom: 10px !important;
    margin-right: 0px !important;
    display: inline-block !important;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  #mapControl {
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
    padding: 20px;
    bottom: 30px;
  }
  .ui.grid {
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .navbarow-dash {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #topControll {
    margin: 0px;
  }
  #topControll .btn-control {
    border-radius: 0px;
    margin: 0px;
    height: 49px;
    width: 33.3%;
    padding: 5px;
  }
  #custom-selectFrom {
    width: 96%;
  }
  #FormFieldOrigenDate {
    max-width: 50%;
  }
  .buttonpackage {
    width: 25%;
  }
  #btntogglemenucont.btn {
    color: #591e8f;
  }

  .ui.container {
    width: 100% !important;
    height: auto !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    .formcontent {
      background-color: aliceblue;
      padding: 5px;
      min-height: 350px;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
  div.containerBanner {
    margin-top: 0px;
  }

  #ajustefromIni {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .bodycontent {
    background-color: $blanco;
    margin-top: 10%;
    top: 0px;
    min-height: auto;
    max-width: 1140px !important;
    // box-shadow: -1px 2px 5px 0px rgba(139, 47, 189, 0.75);
    // -webkit-box-shadow: -1px 2px 5px 0px rgba(136, 51, 201, 0.75);
    // -moz-box-shadow: -1px 2px 5px 0px rgba(159, 39, 189, 0.75);
  }
  .bodycontainerbx {
    background-color: #fff;
  }

  #mapalert {
    h3 {
      padding: 0px;
    }
  }
  #mapsTracking {
    display: block;
    position: relative;
    border-radius: 3px;
    margin-bottom: 20px;
  }

  .table-card {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }

  .card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }

  .card-header {
    align-items: center;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
  }

  .card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    text-align: left !important;
  }

  .card-image {
    border-radius: 15%;
    overflow: hidden;
    height: 80px;
    width: 80px;
  }

  .card-image img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .card-content {
    flex: 1;
  }

  .card-content h3 {
    font-size: 1.2rem;
    margin: 0 0 8px;
  }

  .card-content p {
    font-size: 0.9rem;
    margin: 0;
  }

  .button-container {
    display: block;
  }

  .btn-reporte-excel {
    width: 50%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    margin-bottom: 0;
    padding: 3px;
  }

  #testScroll2.landScreend-testScroll2 {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 100vh;
    background-color: #fff;
    right: 0px;
    padding-bottom: 10%;
    overflow-y: scroll;
    padding: 3px;
    text-align: left;
    background-color: whitesmoke;
    border: 0.5px solid #fff;
    border-radius: 8px;
    background-color: #1e2158;
    position: fixed;
    top: 4em;
    left: 0px;
    bottom: 0px;
  }
}
