
 #topmenus{
 text-align: end;

 }
 div.menudesktop{
    display: inline!important;
    z-index: 10;
    a{  
    font-size: 18px;
    font-weight: 700;
    padding: 5px;
    min-width: 50px;
    color: #FFF;
   }
 }
 div.menumovil{
   z-index: 10;
   display: none;
   background-color: rgb(3, 141, 95);
   a{
      color: #FFF;
   }
 }

@media (max-width: 767px){
   div.menudesktop{
      background-color: aquamarine;
      display: none;
      a{
         color: #FFF;
      }
   }
   div.menumovil{
      display: inline;
      background-color: rgb(3, 141, 95);
      z-index: 10;
      display: inline;
      overflow: hidden;
      width: 100%;
   }

}
