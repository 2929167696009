.expamContainer {
  float: right;
  right: 19px;
  position: absolute;
  z-index: 5;
}

.btn-float-expam {
  background-color: #f8f8f8;
  color: #1e2158;
  border: 1px solid;
  margin: 1%;
  padding: 1px;
}
.btn-float-expam-back {
  background-color: #1e2158;
  color: #f8f8f8;
  border: 1px solid;
  margin: 1%;
  padding: 1px;
}
.expam-maps {
  position: fixed !important;
  height: 100% !important;
  width: 100% !important;
  top: 5.5em !important;
  left: 0px !important;
  padding: 0px !important;
  z-index: 900 !important;
  background-color: #f8f8f8 !important;
}
.edicitionContainer {
  background: #f1f1f1;
  position: relative;
  margin: 0;
  padding: 8px 18px;
  display: flex;
}
.btn-edition-planner {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 12px;
  color: #1e2158;
  font-weight: 400;
  line-height: 20px;
  background-color: transparent;
  cursor: pointer;
}

.featurelist-scrollable-container {
  display: block;
}

.featurelist-scrollable-container-div {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  // height: fit-content;
  max-height: 400px;
}
.dispachtelementGroup {
  background-color: white;
  padding: 10px 5px 10px 5px;
  width: 92% !important;
}
.dispachtelementGroup:hover {
  background-color: white;
  box-shadow: 0 0 5px 5px #ccc;
}

.listContentitems {
  background-color: #f8f8f8;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
}
.listContentitemsgroup {
  background-color: #f8f8f8;
  border-left: 5px solid #591e8f;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 5px;
  display: block;
  margin-left: 1px;
  width: max-content;
}

.listContentitemsgroup-disable {
  background-color: #f8f8f8;
  border-left: 5px solid transparent;
  margin-left: 1px;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
}

.zoneName {
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 21px;
  position: relative;
}
.listContentitem {
  box-sizing: border-box;
  position: relative;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e5e5;
}
.checkItem {
  margin-right: 3px;
}

.zoneNameh5 {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: 700;
  padding: 0;
  font-size: 1rem;
}
button.gm-ui-hover-effect {
  visibility: hidden;
  display: none;
}
.posditonCloseInfo {
  position: absolute;
  padding: 3px;
  font-size: 1rem;
  font-weight: 700;
  color: red;
  border: 1px solid;
  border-radius: 100px;
  top: 3px;
  right: 3px;
}
.alert-analitys {
  width: 50%;
  margin: 0px;
  padding: 1px 8px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0 0 15px 5px #ccc;
  color: #fff;
  background-color: #f51b1b;
  font-size: 11px;
}
.btn-crearplan-ruta {
  width: 45%;
  background: green;
  color: #fff;
  font-size: 12px;
  padding: 4px;
}
.btn-clearplan-ruta {
  width: 45%;
  background: red;
  font-size: 12px;
  padding: 4px;
}
.pinEstatusInRoute {
  border: 1px solid #e7c60a;
  font-size: 11px;
  padding: 4px 6px;
  color: black;
  background: #ffd700;
  border-radius: 15px;
  font-weight: bold;
}

.pinEstatusCancel {
  border: 1px solid #e7190a;
  font-size: 11px;
  padding: 4px 6px;
  color: white;
  background: #ff4d4d;
  border-radius: 15px;
  font-weight: bold;
}

.pinEstatusDelivery {
  border: 1px solid #1eae1e;
  font-size: 11px;
  padding: 4px 6px;
  color: white;
  background: #32cd32;
  border-radius: 15px;
  font-weight: bold;
}

.pinEstatusNoCreate {
  border: 1px solid #9d9e9d;
  font-size: 11px;
  padding: 4px 6px;
  color: red;
  background: #d3d3d3;
  border-radius: 15px;
  font-weight: bold;
}

.pinEstatusDevolucion {
  border: 1px solid #e67e22;
  font-size: 11px;
  padding: 4px 6px;
  color: white;
  background: #ff8c00;
  border-radius: 15px;
  font-weight: bold;
}

.itemListRoute {
  text-align: center;
  justify-content: space-between;
  display: flex;
  margin: 2px;
}
.containerroutesdiv {
  width: 100% !important;
}
.Vertical-table-width100 {
  width: 100%;
}
#Form-Field-Edit-Route {
  padding-left: 1px !important;
}
#Form-Field-Edit-Route-initHour {
  width: 100%;
}
#Form-Field-Edit-Route-edithOrigen {
  margin: 0;
  max-width: 80%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  padding: 0.67857143em 1em;
  padding-top: 0.678571em;
  padding-right: 0em;
  padding-bottom: 0.678571em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}
#Form-Field-Edit-Route-edithDestini {
  margin: 0;
  max-width: 80%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  padding: 0.67857143em 1em;
  padding-top: 0.678571em;
  padding-right: 0em;
  padding-bottom: 0.678571em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: none;
}

.button-seleccion-rider {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
  min-width: 250px;
  max-width: 100%;
  height: 100%;
  min-height: 45px;

  border: 1px solid #e6e0e0;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  // -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 3px;
  overflow: hidden;
}
.button-seleccion-rider-button {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
  height: 100%;
  min-height: 45px;
  border-color: transparent;
  left: 0px;
  right: 0px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.driverselectorcard {
  display: inline-flex;
  text-align: left;
  border-radius: 50%;
}

.DataGridStyle {
}
.typeMiniformsPlanner {
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  width: 100% !important;
}

.dispachtListManifest {
  width: 100% !important;
  padding: 10px;
}
.manifesFontsite20 {
  font-size: 18px !important;
}
.manifesFontsite {
  font-size: 15px !important;
}
.manifesColorsFonts {
  color: #050505;
}
.logoManifest {
  max-width: 150px;
}
.manifesContainer {
  padding: 10px;
}
.btn-optimizar {
  border: 1px solid #1eae1e;
  font-size: 12px;
  padding: 3px 3px;
  color: #fff;
  background: #1eae1e;
  border-radius: 18px;
  width: 100%;
  font-weight: 400;
}

.positionmarker {
  padding: 3px;
  background: #c7c7c7;
  border: 1px solid #c7c7c7;
  color: #000;
}
#mapsControlPlanRoutes {
  padding: 3px;
  background-color: rgba(255, 255, 255, 0.651);
  border-radius: 3px;
  margin-bottom: 20px;
  z-index: 12;
  min-width: 350px;
  box-shadow: rgba(48, 48, 48, 0.35) 0px 5px 15px !important;
  height: Auto !important;
  max-width: 400px;
  float: right;
  position: absolute;
  right: 6%;
  color: #000;
  top: 4%;
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll;
}
// #mapControl > div > div > div:nth-child(2) > div:nth-child(2) > div > div:nth-child(4) > div:nth-child(3) > img{
//     display: none;
// }
.marker-label-ebiex {
  width: auto;
  min-height: 20px;
  background-color: rgb(230, 230, 230);
  color: #3c0ce9;
  text-align: center;
  padding: 5px 3px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  margin-left: 20px;

  border: 1px solid #591e8f;
}

.mapControlStyle {
  text-align: center;
}

#mapControl2 {
  height: 90vh;
  width: 90%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
}
#mapControl3 {
  height: 90vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
}
.titleDistribucion {
  margin-top: 10px;
  margin-left: 10px;
}

.contenOrdersforMap {
  position: fixed;
  max-width: 25%;
  max-height: 100%;
  right: 41px;
  top: 5%;
  bottom: auto;
  width: 270px;
  background-color: #f8f8f8;
  border: 1px solid #f8f8ed;
  padding: 25px;
  border-radius: 14px;
  overflow-y: scroll;
}
