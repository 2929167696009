.float-letf {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(152, 243, 157);
  padding: 4px !important;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px #ccc;
  font-size: 10px !important;
}
.labelupload {
  display: inline-block;
  margin-bottom: 1rem;
}

.labelupload label[for="file-upload"] {
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: #591e8f;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.labelupload label[for="file-upload"]::before {
  content: "Subir archivo";
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  padding-right: 0.5rem;
}

.labelupload input[type="file"] {
  display: none;
}

.labelupload .choose-file {
  content: "Elegir archivo";
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #591e8f;
  border-radius: 0.25rem;
  cursor: pointer;
}

.labelupload input[type="file"]:focus + .choose-file {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

#file-upload-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #dc3545;
  display: none;
}

@media (max-width: 576px) {
  .labelupload label[for="file-upload"] {
    font-size: 0.875rem;
    line-height: 1.25;
  }

  .labelupload label[for="file-upload"]::before {
    font-size: 0.875rem;
  }
}
