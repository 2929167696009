.center-alert {
  padding-top: 20px;
  text-align: center;
  width: 100%;
}
.resumenOrdenes {
  padding: 30px;
}

.resumenOrdenesContainer {
  padding-top: 20px;
  text-align: center;
  width: 100%;
}
.resumenOrdenesContent {
  max-width: 850px;
  min-height: 750px;
  margin-left: auto;
  margin-right: auto;
}
.cardarticle-sussces {
  background-color: #d5ffd5;
  text-align: left;
}

.card-container {
  justify-content: space-between;
}
.ebiex-iphone12-r {
  margin-left: 5px;
  width: 35%;
}
.ebiex-iphone12-l {
  width: 60%;
  margin-right: 5px;
}
.card-article-center {
  text-align: center;
}

.seller_header-foot {
  vertical-align: middle;
}
@media only screen and (max-width: 950px) {
  .ebiex-iphone12-r {
    margin-left: 0px;
    width: 100%;
  }
  .ebiex-iphone12-l {
    width: 100%;
    margin-right: 0px;
  }
}
.checkoutImages-scuale {
  max-width: 200px !important;
  border-radius: 8px !important;
}
