.bodycontent{
    box-shadow: none;
}
.bannertiposdeenvio{
    background-color: #3BCDDC;
    width: 100%;
    min-height: 350px;
    text-align: center;
}
.fondoclapso{
    background-color: #3BCDDC!important;
}
.fondobicci{
    background-color: #741AB0!important;
}

.metricasenvio{
    background-color: #fff;
    width: 100%;
    min-height: 350px;
    text-align: center;
}
.subtilepage{
    font-size: 25px;
    font-weight: 600;
}

.bannertiposdeservicio{
    background-color: #fff;
    width: 100%;
    min-height: 450px;
    text-align: center;
}

.font-sizeBig{
    font-size: 22px!important;
    line-height: 2;
}

.colorBlack{
    color: black!important;
}

.colorWhite{
    color: #FFF!important;
}
.fonZiseBig{
    font-size: 30px;
}
.cardHomeContent{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding-left: 5%!important;
    padding-right: 5%!important;
}
.cardHome{
    height: 20%!important;
    height: 370px!Important;
}
.Home_cardContent{
    max-width: 100px!important;
    margin: 5px;
    width: 100px!important;
    max-height: 160px!important;
    min-width: 160px!important;
    background-color: rgb(255, 255, 255)!important;
}
.justefi-padding{
    padding-top: 56px!important;
    padding-bottom: 4px!important;
}
.justefi-padding01{
    margin-bottom: 15px;
}
.adjustment-marging{
    margin-bottom: 20px;
    background-color: red!important;
}
.Home_cardContent160{
    max-width: 100px!important;
    margin: 5px;
    width: 100px!important;
    height:  130px!important;
    max-height: 160px!important;
    min-width: 160px!important;
    background-color: #FFF!important;
    padding-top: 20px;
}
.Home_cardContent3{
    max-width: 150px;
    max-height: 100px;
    margin: 5px;
    min-width: 150px;
    min-width: 100px;
}
.rowmarging{
    padding-left: 10%!important;
    padding-right: 10%!important;
}
.rowmargingsmall{
    padding-left: 25%!important;
    padding-right: 25%!important;
}
.imgbotomsr{
    float: right!important;
    max-width: 50%!important;
}

.rowblock{
   text-align: right;
   max-width: 465px;
   background-color: #5de7f7!important;
   background-image: linear-gradient(to right bottom, #a4f6ff, #8df1fd, #72ecfc, #50e6fb, #00e1fb);

   margin-bottom: 5px;
   padding: 5px;
   border-radius: 8px;
}
.rowblockr{
    margin-top: 5em;
 }

 


.imgbotomsl{
    float: left!important;
    max-width: 50%!important;
}
.space40{
    max-width: 40%;
    width: 40%;
}
.space60{
    max-width: 60%;
    max-width: 60%;
}
.columnright{
    text-align: right;
}
.columnleft{
    text-align: left;
}
.resaltado{
    font-size: 1.875rem;
}
.min-heght{
    min-height: 100px;
}
.cardHome3{
    box-shadow: rgba(96, 80, 117, 0.562)!important;
    border: 1px solid #e3e3e3;
    border-radius: 1rem;
    min-height: 350px;
    height: 400px;
    padding: 30px 0px  30px  3px ;

}
.services_header{
    display: flex!important;
    align-items: center!important;
    height: 35px!important;
    text-align: left;
}
.spacedivider{
    height: 50px!important;
}

.services_border{
    background-color: #741AB0!important;
    width: 10px!important;
    height: 25px!important;
    margin-right: 1rem!important;
}
.services_badgeContainer{
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem;
    min-height: 28px;
    margin-bottom: 0.75rem;
}
.services_badge{
    display: block;
    font-weight: 600;
    background-color: rgba(132,196,64,.25);
    padding: 0.5rem;
    border-radius: 5px;
}

@media only screen and (max-width: 740px){
    .rowmargingsmall{
        padding-left: 2%!important;
        padding-right: 2%!important;
    }
}

