.documentsApiapp {
  display: flex;
  flex-direction: row;
  height: 100vh;
  color: "#000";
}

.sidebar {
  background-color: #121828;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar button {
  background: none;
  border: none;
  color: blue;
  text-align: left;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
}

.sidebar button:hover {
  text-decoration: underline;
}

.content {
  flex: 1;
  padding: 20px;
  color: "#030303";
}

.code-example {
  background-color: #000000;
  color: white;
  padding: 20px;
  border-radius: 5px;
  height: 100vh;
}

.code-example pre {
  margin: 0;
}

.code-example code {
  display: block;
  white-space: pre-wrap;
  color: white;
}

#SplitPaneContent {
  .Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
  }
  .Resizer:hover {
    transition: all 2s ease;
  }
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }
  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Pane1,
  .Pane2 {
    overflow: auto;
  }

  .contentContainer {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    color: "#000";
  }
}

.splitPaneContent {
  display: flex;
  flex-direction: row;
}

.splitPaneContent > div {
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .documentsApiapp {
    flex-direction: column;
  }

  .contentContainer {
    padding: 10px;
  }

  .sidebar-api {
    width: 100%;
    box-shadow: none;
  }

  .splitPaneContent {
    flex-direction: column;
  }

  .splitPaneContent > div {
    height: 100%;
  }

  .content {
    flex: 1;
    padding: 1px;
    color: "#030303";
  }

  .splitPaneContentText {
    padding: 0px;
  }
}
