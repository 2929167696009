.mapControlPublic {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  bottom: 33px;
}
.container-control-ruta {
  //float: left;
  position: block;
  z-index: 3;
  background-color: #f8f8f8;
  font-size: 15px;
  color: #030303;
  border-radius: 14px;
  box-shadow: aquamarine;
  left: 1%;
  bottom: 20%;
  -webkit-box-shadow: -4px 4px 5px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 4px 5px -3px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 4px 5px -3px rgba(0, 0, 0, 0.75);
}
.title-container-control-ruta-letf {
  color: #030303;
  font-size: 1.5em;
  color: #030303;
  font-size: 1.5em;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}
.title-container-control-ruta-rithg {
  color: #030303;
  font-size: 1.5em;
  color: #030303;
  font-size: 1.5em;
  width: 100%;

  display: flex;
  justify-content: flex-end;
}
.text-container-control-ruta-left {
  color: #030303;
  font-size: 1.5em;
  color: #030303;
  font-size: 1em;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-content: center;
}
.itemOrder {
  float: left;
  display: block;
  z-index: 3;
  background-color: #f8f8f8;
  min-width: 14px;
  width: 175px;
  padding: 20px;
  font-size: 19px;
  font-weight: 700;
  color: #030303;
  border-radius: 3px;
  box-shadow: aquamarine;
  margin-bottom: 2px;
  -webkit-box-shadow: -4px 4px 5px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px 4px 5px -3px rgba(0, 0, 0, 0.75);
  box-shadow: -4px 4px 5px -3px rgba(0, 0, 0, 0.75);
}
.OpcionaContainerInfoWindow {
  width: 100%;
  min-width: 100%;
  border-top-left-radius: 15%;
  border-top-right-radius: 15%;
  position: fixed;
  bottom: 0px;
  padding-bottom: 50px;
  -webkit-box-shadow: 0px -7px 45px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -7px 45px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -7px 45px 0px rgba(0, 0, 0, 0.75);
}
#imputSearchFromOrigenRoutes {
  display: block;
  width: 100% !important;
  padding: 5px !important;
  font-size: 1em !important;
  line-height: 1.5;
  color: #030507;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}

.modalBody {
  background-color: aqua;
}
.boxstyle {
  position: "absolute" !important;
  top: 20% !important;
  right: 20% !important;
  transform: "translate(-50%, -50%)" !important;
  width: 600 !important;
  background-color: "#f8f8f8" !important;
  border: "1px solid #000" !important;
  box-shadow: 24 !important;
  padding: 20px !important;
  overflow: "visible" !important;
  margin-top: 10% !important;
  margin-bottom: 10% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.imput-modal-routeMaps {
  border: 0.5 solid grey !important;
}
.comboboxList-ebiex-modal {
  padding-top: 0px;
  z-index: 300 !important;
  display: block !important;
  border: unset !important;
}

.comboboxList-ebiex-modal-items {
  z-index: 999 !important;
  background-color: #f8f8f8 !important;
  position: absolute !important;
  overflow: visible;
  color: #000;
  font-size: 12px;
}
.labelMarker {
  background-color: #ffffff;
  padding: 3px;
  position: relative;
  margin-left: 8em;
  min-width: 70px;
  margin-bottom: 2em;
  border-radius: 3px;
  border: 0.5px solid #5117d6;
  -webkit-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
}
.labelMarker-pin {
  background-color: #ffffff;
  padding: 3px;
  font-size: 5px;
  position: relative;
  margin-left: 2em;
  margin-bottom: 2em;
  border-radius: 3px;
  border: 0.5px solid #5117d6;
  -webkit-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
}
.labelMarker-pin-rider {
  background-color: transparent;
  padding: 3px;
  font-size: 10px;
  position: relative;
  margin-left: 1em;
  margin-bottom: 3em;
  border-radius: 3px;
  border: none;
  -webkit-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
}
.labelMarkerOrigen {
  background-color: #f8f8f8;
  font-weight: 600;
  padding: 3px;
  position: relative;
  margin-left: 8em;
  margin-bottom: -11em;
  border-radius: 3px;
  min-width: 70px;
  border: 0.5px solid #5117d6;
  -webkit-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 10px 0px -9px rgba(0, 0, 0, 0.75);
}
.overlay-OpcionaContainerInfoWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  pointer-events: auto;
}
.alternativeColorDhas {
  color: "#1e2158";
}
.comboboxList-routes-direcctiones {
  z-index: 3000 !important;
  position: relative;
  padding-top: 0px;
  width: max-content;
}

.routesfromIni {
  width: 100%;
  padding: 0px;
  height: fit-content;
}
#containerroutesfromIni {
  max-height: fit-content;
  width: 100% !important;
  min-height: fit-content;
  padding: 5px;
}
#containerroutesfromIorig {
  max-height: fit-content;
  width: 100% !important;
  height: 150px !important;
  min-height: fit-content;
}

.btn-control-route-container {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 33.3%;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  text-transform: none;
}
#topControlRoutes .btn-control {
  margin: 0px;
  height: 49px;
  width: 33%;
  padding: 5px;
  border-radius: 0px;
  margin: 0px;
  height: 32px;
}
.btn-control-route {
  border-radius: 0px;
  margin: 0px;
  height: 100%;
}

#topControlRoutes .btn-control-icon-button {
  margin: 0px;
  height: 100%;
  border-radius: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: "#00cdf6";
}

#topControlRoutes {
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

.marketr-icon {
  color: blue;
  max-width: 35px !important;
  width: 35px !important;
}
.linearButtons {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.labelMarker-excep {
  background-color: #f8f8f8;
  font-size: 8px;
  font-weight: 300;
  padding: 5px;
  position: relative;
  margin-left: 8em;
  margin-bottom: -11em;
  border-radius: 14px;
}
/* Deshabilita el zoom en todos los elementos */
// body {
//   touch-action: manipulation; /* Evita el doble toque en dispositivos táctiles */
//   overflow: hidden; /* Evita el desplazamiento horizontal y vertical */
// }
// *,
// *:before,
// *:after {
//   zoom: reset !important;
//   touch-action: manipulation !important;
// }
