.DashIconsResumencard{
padding: 3px;
min-width: 23%!important;
//width:25%!important;
}
.dashIcon{
    font-size: 3em;
    color:blue
}
.dashIconsresumencardcontent{
    text-align: center;
    padding: 3px!important;
  
}
.dashicontitle{
    padding: 3px;
    margin: 3px;
}

.dashIcon-blue{
    color:blue
}
.dashIcon-grey{
    color:rgb(158, 158, 158)
}
.dashIcon-green{
    color:rgb(173, 231, 173)
}
.dashIcon-on-line{
    color:rgb(17, 240, 103)
}
.dashIcon-red{
    color:rgb(240, 50, 17)
}
.dashicontspanbigtext{
    padding: 1px;
    font-size: 3em;

}
.dashicontspansmalltext{
    padding: 1px;
    font-size: 0.7em;
}
