@media only screen and (min-width: 950px) {
  .nonstyle {
    font-size: 12px !important;
    background-color: transparent !important;
    cursor: pointer !important;
    display: inline-blockpointer !important;
    min-height: 0pxpointer !important;
    outline: 0pxpointer !important;
    border: nonepointer !important;
    vertical-align: baselinepointer !important;
    background: transparentpointer !important;
    color: rgba(0, 0, 0, 0.6) pointer !important;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
    margin: 0pxpointer !important;
    padding: 0pxpointer !important;
    text-transform: nonepointer !important;
    text-shadow: nonepointer !important;
    font-weight: 700pointer !important;
    line-height: 12pxpointer !important;
    font-style: normalpointer !important;
    text-align: centerpointer !important;
    text-decoration: nonepointer !important;
    border-radius: 0rempointer !important;
    box-shadow: 0 0 0 0px transparent inset, 0 0 0 0 transparent inset;
    -webkit-user-select: nonepointer !important;
    user-select: nonepointer !important;
    transition: unsetpointer !important;
    will-change: "" pointer !important;
    -webkit-tap-highlight-color: transparentpointer !important;
  }

  .footerpageback {
    background-color: transparent;
    //background: linear-gradient(90deg, #591e8f 0%, #490979 37%, #00cdf6 100%);
  }
  .footerpage {
    background: transparent !important;
    height: 50px !important;
  }
  .footer-cards {
    background-color: #fff;
    border-radius: 15px;
    margin: 1px;
    width: 98%;
  }

  #menufootercenter {
    width: 80%;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 740px) {
  .footerpage {
    width: 100%;
    height: 350px;
    background: linear-gradient(90deg, #591e8f 0%, #490979 37%, #00cdf6 100%);
    bottom: 100px;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 80px 20px;
    justify-content: center;
  }
  .footerpageback {
    background-color: transparent;
  }
  img.ui.image.foo {
    max-width: 100px;
  }
  #menufootercenter {
    font-size: 12px;
    padding-top: 20px;
  }
  #menufooter {
    font-size: 12px;
    padding-top: 20px;
  }
  #menufootercenter2 {
    font-size: 12px;
    padding-top: 20px !important;
    display: block;
  }
  .close {
    display: none !important;
  }
  .open {
    display: true !important;
  }
}

@media only screen and (max-width: 740px) {
  .footerpageback {
    background-color: transparent;
  }
  img.ui.image.foo {
    max-width: 100px;
  }
  #menufootercenter {
    font-size: 10px;
    padding-top: 0px;
    display: inline-flex;
  }
  #menufooter {
    font-size: 10px;
    padding-top: 20px;
  }
  #menufootercenter2 {
    font-size: 12px;
    padding-top: 0px;
    display: block;
    span {
      div {
        display: none;
      }
      p.foo-inline-flex {
        a {
          margin: 2px !important;
          text-align: center !important;
          color: #591e8f !important;
        }
      }
    }
  }
  .foo-inline-flex {
    display: inline-flex;
  }
  .foo-inline-block {
    display: blok;
  }
  .footer-btn-menu {
    display: none;
  }
  .close {
    display: none !important;
  }
  .open {
    display: true !important;
  }
}
.footer-cards {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 2px;
}
.footerpageback {
  //background-color: #dfdfdf;
  width: 100%;
}
.foo {
  max-width: 150px;
}

.footerpage {
  width: 100%;
  height: 400px;

  background: linear-gradient(90deg, #591e8f 0%, #490979 37%, #00cdf6 100%);
  bottom: 100px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px 80px 20px;
  justify-content: center;
}
#menufootercenter {
  font-size: 12px;
  padding-top: 20px;
}
#menufooter {
  font-size: 12px;
  padding-top: 20px;
}

#calltrap-btn {
  background-color: #47c756;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: block;
  height: 64px;
  line-height: 100px;
  opacity: 0.7;
  position: fixed;
  right: 3px;
  bottom: 3px;
  text-align: center;
  width: 64px;
  z-index: 9999;
  -webkit-animation: calltrap_opacity 3s infinite linear;
  -moz-animation: calltrap_opacity 3s infinite linear;
  -o-animation: calltrap_opacity 3s infinite linear;
  animation: calltrap_opacity 3s infinite linear;
}

#calltrap-btn.calltrap_offline {
  background-color: #1cb2ed;
}

#calltrap-ico {
  width: 32px;
  height: 32px;
  position: absolute;
  margin: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: 0;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  -o-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-animation: uptocallmini 1.5s 0s ease-out infinite;
  -moz-animation: uptocallmini 1.5s 0s ease-out infinite;
  -o-animation: uptocallmini 1.5s 0s ease-out infinite;
  animation: uptocallmini 1.5s 0s ease-out infinite;
}

#calltrap-ico:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  left: 0;
  // background-image: url("phone-call.png");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-animation: calltrap_spin 3s infinite linear;
  -moz-animation: calltrap_spin 3s infinite linear;
  -o-animation: calltrap_spin 3s infinite linear;
  animation: calltrap_spin 3s infinite linear;
}

.calltrap_offline #calltrap-ico {
  -webkit-animation: uptocallmini_offline 1.5s 0s ease-out infinite;
  -moz-animation: uptocallmini_offline 1.5s 0s ease-out infinite;
  -o-animation: uptocallmini_offline 1.5s 0s ease-out infinite;
  animation: uptocallmini_offline 1.5s 0s ease-out infinite;
}

#calltrap-overlay {
  background-color: #191919;
  background-color: rgba(25, 25, 25, 0.9);
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  display: none;
}

#calltrap-overlay .b-calltrap-overlay {
  display: table;
  width: 100%;
  height: 100%;
}

#calltrap-overlay .b-calltrap-overlay__header,
#calltrap-overlay .b-calltrap-overlay__body,
#calltrap-overlay .b-calltrap-overlay__footer {
  display: table-row;
  padding: 0;
  margin: 0;
}

#calltrap-overlay .b-calltrap-overlay__header-block,
#calltrap-overlay .b-calltrap-overlay__body-block,
#calltrap-overlay .b-calltrap-overlay__footer-block {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#calltrap-overlay .b-calltrap-overlay__body-block {
  padding: 0 7px;
}

#calltrap-overlay .b-calltrap-overlay__header,
#calltrap-overlay .b-calltrap-overlay__footer {
  height: 1px;
}

#calltrap-overlay .b-calltrap__container {
  background-color: white;
  border: 5px solid #2074a1;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  max-width: 500px;
  display: block;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.3;
  position: relative;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-overlay .b-calltrap__container {
    width: 290px;
  }
}

#calltrap-overlay .b-calltrap__close {
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#calltrap-overlay .b-calltrap__close:hover {
  opacity: 1;
}

#calltrap-success,
#calltrap-form {
  display: block;
}

#calltrap-success .b-calltrap_form__fields,
#calltrap-form .b-calltrap_form__fields {
  margin: 20px 25px 35px;
}

#calltrap-success .b-calltrap_form__title,
#calltrap-form .b-calltrap_form__title {
  font-size: 20px;
  margin: 0 0 17px;
  text-align: left;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__title,
  #calltrap-form .b-calltrap_form__title {
    padding-right: 20px;
  }
}

#calltrap-success .b-calltrap_form__text,
#calltrap-form .b-calltrap_form__text {
  text-align: left;
}

#calltrap-success .b-calltrap_form__text p,
#calltrap-form .b-calltrap_form__text p {
  margin: 0 0 0.55em;
}

#calltrap-success .b-calltrap_form__input,
#calltrap-form .b-calltrap_form__input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 15px 0;
  height: 44px;
  line-height: 40px;
  background-color: white;
  border: 2px solid #ccc;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  display: block;
  width: 100%;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__input,
  #calltrap-form .b-calltrap_form__input {
    margin-bottom: 13px;
  }
}

#calltrap-success .b-calltrap_form__input.error,
#calltrap-form .b-calltrap_form__input.error {
  background-color: #ffc0c0;
  color: red;
  border-color: red;
}

#calltrap-success .b-calltrap_form__input:disabled,
#calltrap-form .b-calltrap_form__input:disabled {
  background-color: #eee;
  border-color: #bdbdbd;
  cursor: default;
}

#calltrap-success .b-calltrap_form__input:focus,
#calltrap-form .b-calltrap_form__input:focus {
  border: 2px solid #2074a1;
}

#calltrap-success .b-calltrap_form__submit,
#calltrap-form .b-calltrap_form__submit {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #2074a1;
  border: 2px solid #2074a1;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  height: 44px;
  line-height: 40px;
  outline: medium none;
  display: inline-block;
  width: 180px;
  text-align: center;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__submit,
  #calltrap-form .b-calltrap_form__submit {
    width: 100%;
  }
}

#calltrap-success .b-calltrap_form__submit:hover,
#calltrap-form .b-calltrap_form__submit:hover {
  background-color: #1c658c;
}

#calltrap-success .b-calltrap_form__submit:disabled,
#calltrap-form .b-calltrap_form__submit:disabled {
  background-color: #bdbdbd !important;
  cursor: default;
}

#calltrap-success .b-calltrap_form__controls,
#calltrap-form .b-calltrap_form__controls {
  margin: 20px 0 0;
  padding: 0;
  padding: 0 200px 0 0;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__controls,
  #calltrap-form .b-calltrap_form__controls {
    padding: 0;
  }
}

#calltrap-success .b-calltrap_form__controls-item,
#calltrap-form .b-calltrap_form__controls-item {
  position: relative;
  float: right;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__controls-item,
  #calltrap-form .b-calltrap_form__controls-item {
    float: none;
  }
}

#calltrap-success .b-calltrap_form__controls-left,
#calltrap-form .b-calltrap_form__controls-left {
  margin-left: -100%;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__controls-left,
  #calltrap-form .b-calltrap_form__controls-left {
    margin-left: 0;
  }
}

#calltrap-success .b-calltrap_form__controls-right,
#calltrap-form .b-calltrap_form__controls-right {
  margin-right: -200px;
  overflow: visible;
  width: 200px;
  text-align: right;
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  #calltrap-success .b-calltrap_form__controls-right,
  #calltrap-form .b-calltrap_form__controls-right {
    margin-right: 0;
    width: auto;
    text-align: left;
  }
}

#calltrap-success .b-calltrap_form__controls-clear,
#calltrap-form .b-calltrap_form__controls-clear {
  clear: both;
}

#calltrap-success {
  display: none;
}

#calltrap-overlay.calltrap_offline .b-calltrap_form__submit {
  background-color: #1cb2ed;
}

#calltrap-overlay.calltrap_offline .b-calltrap_form__submit:hover {
  background-color: #2e8c1c;
}

#calltrap-overlay.calltrap_offline .b-calltrap_form__input:focus {
  border-color: #1cb2ed;
}

#calltrap-overlay.calltrap_offline .b-calltrap__container {
  border-color: #1cb2ed;
}

@-webkit-keyframes calltrap_opacity {
  0% {
    opacity: 1;
  }
  57% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes calltrap_opacity {
  0% {
    opacity: 1;
  }
  57% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes calltrap_opacity {
  0% {
    opacity: 1;
  }
  57% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

@keyframes calltrap_opacity {
  0% {
    opacity: 1;
  }
  57% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes calltrap_spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-moz-keyframes calltrap_spin {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-o-keyframes calltrap_spin {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes calltrap_spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  36% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  39% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  42% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  48% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
    opacity: 1;
  }
  51% {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  54% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  57% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes uptocallmini {
  0% {
    -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0),
      0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0);
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(32, 116, 161, 0);
  }
  10% {
    -webkit-box-shadow: 0 0 8px 6px #2074a1, 0 0 12px 10px transparent,
      0 0 12px 14px #2074a1;
    box-shadow: 0 0 8px 6px #2074a1, 0 0 12px 10px transparent,
      0 0 12px 14px #2074a1;
  }
  100% {
    -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0),
      0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0);
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(32, 116, 161, 0);
  }
}

@-moz-keyframes uptocallmini {
  0% {
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(32, 116, 161, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #2074a1, 0 0 12px 10px transparent,
      0 0 12px 14px #2074a1;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(32, 116, 161, 0);
  }
}

@-o-keyframes uptocallmini {
  0% {
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(32, 116, 161, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #2074a1, 0 0 12px 10px transparent,
      0 0 12px 14px #2074a1;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(32, 116, 161, 0);
  }
}

@keyframes uptocallmini {
  0% {
    -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0),
      0 0 0px 0px transparent, 0 0 0px 0px rgba(32, 116, 161, 0);
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(32, 116, 161, 0);
  }
  10% {
    -webkit-box-shadow: 0 0 8px 6px #2074a1, 0 0 12px 10px transparent,
      0 0 12px 14px #2074a1;
    box-shadow: 0 0 8px 6px #2074a1, 0 0 12px 10px transparent,
      0 0 12px 14px #2074a1;
  }
  100% {
    -webkit-box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0),
      0 0 0px 40px transparent, 0 0 0px 40px rgba(32, 116, 161, 0);
    box-shadow: 0 0 8px 6px rgba(32, 116, 161, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(32, 116, 161, 0);
  }
}

@-webkit-keyframes uptocallmini_offline {
  0% {
    -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0),
      0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0);
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(53, 161, 32, 0);
  }
  10% {
    -webkit-box-shadow: 0 0 8px 6px #35a120, 0 0 12px 10px transparent,
      0 0 12px 14px #35a120;
    box-shadow: 0 0 8px 6px #35a120, 0 0 12px 10px transparent,
      0 0 12px 14px #35a120;
  }
  100% {
    -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0),
      0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0);
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(53, 161, 32, 0);
  }
}

@-moz-keyframes uptocallmini_offline {
  0% {
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(53, 161, 32, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #35a120, 0 0 12px 10px transparent,
      0 0 12px 14px #35a120;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(53, 161, 32, 0);
  }
}

@-o-keyframes uptocallmini_offline {
  0% {
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(53, 161, 32, 0);
  }
  10% {
    box-shadow: 0 0 8px 6px #35a120, 0 0 12px 10px transparent,
      0 0 12px 14px #35a120;
  }
  100% {
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(53, 161, 32, 0);
  }
}

@keyframes uptocallmini_offline {
  0% {
    -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0),
      0 0 0px 0px transparent, 0 0 0px 0px rgba(53, 161, 32, 0);
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(53, 161, 32, 0);
  }
  10% {
    -webkit-box-shadow: 0 0 8px 6px #1cb2ed, 0 0 12px 10px transparent,
      0 0 12px 14px #1cb2ed;
    box-shadow: 0 0 8px 6px #1cb2ed, 0 0 12px 10px transparent,
      0 0 12px 14px #1cb2ed;
  }
  100% {
    -webkit-box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0),
      0 0 0px 40px transparent, 0 0 0px 40px rgba(53, 161, 32, 0);
    box-shadow: 0 0 8px 6px rgba(53, 161, 32, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(53, 161, 32, 0);
  }
}
