.center{
   text-align: center;
}
.topflex{
    display: flex;
    text-align: center;
}
.color-success{
    color: green;
}
.color-solid{
    color: #000;
}
.route-name{
    font-size: 24px;
    color: gray;
    font-weight: 600;
}
span.min-w-space-flag{
min-width: 150px!important;
text-align: left;
}
.dashroutesadd_bar{
    width: 80%;

    padding: 1px;
   
}
.dashroutesadd_bar_right{
    width: 20%;
    display: inline;
}
.dashroutesadd_bar_donw_right{
    width: 20%;
    display: inline;
    padding-top: 20px;
}
.btn-add-guide{
    padding: 10px;
    background: #7d0ee6 !important;
    display: inline-block !important;
    padding: 8px 35px 10px 35px !important;
    border-radius: 4px !important;
    color: #f8f8f8;
    font-size: 15px;
    font-weight: 600;
    margin: 1px;
    line-height: 1.5;
}
.btn-edit-guide{
    padding-top: 20px;
    background: #7d0ee6 !important;
    display: inline-block !important;
    padding: 8px 35px 10px 35px !important;
    border-radius: 4px !important;
    color: #f8f8f8;
    font-size: 15px;
    font-weight: 600;
    margin: 1px;
    line-height: 1.5;
}
.textModalContent{
    color: #000;
    font-size: 15px;
    font-weight: 300;
    font-size: 15px;
}

.dashroutesadd_bar_top{
    display: flex;
    width: 100%;
    padding: 1px;
 
    color: #000;
    height: 2em;
}
.dashroutesadd_bar_top_left{
    width: 50%;
    padding: 1px;
    color: #000;
    text-align: left;
}
.dashroutesadd_bar_top_right{
    width: 50%;
    padding: 1px;
    color: #000;
    text-align: right;
}
button{
    outline: none !important;
}
.dashroutesadd_bar_donw{
    display: flex;
    width: 100%;
    height: 2em;
}
.dashroutesadd_bar_donw_left{
    width: 50%;
    padding: 1px;
    color: #000;
    text-align: left;
}
.dashroutesadd_bar_donw_right{
    width: 20%;
    color: #000;
    text-align: center;
}
.dashroutesadd_bar_donw_right-alt{
    width: 50%;
    color: #000;
    text-align: right;
}

.dashroutesadd_bar_donw_right  button{ 
    padding: 15px;
    margin: auto;
    background: #55e06f !important;
    display: inline-block !important;
    padding: 8px 35px 10px 35px !important;
    border-radius: 4px !important;
}
.containerroutesdispachst{
    width: 100% !important;
    min-height: 450px;
    height: 100%;
    padding-top: 5px;
    display: block!important;
}
.bar-topflex{
    vertical-align: middle;

    display: flex;
    width: 100%;
    min-height: 50px;

}
.bar-topflex-left{
    width: 50%;
    text-align: left;
    padding-top: 5px;
    padding-left: 5px;
}
.bar-topflex-rigth{
    width: 50%;
    text-align: right;
}
.container-routes{
    display: flex;
    width: 100%;
 
    min-height: 250px;
}

.font-size-big{
    font-size: 2em!important;
    color: #f8f8f8;
}
.btn-maps-routes{
    margin: 3px;
    max-width: 150px;
    padding: 10px !important;
    background: #7d0ee6 !important;
    border-radius: 50%;
}

.btn-maps-routes-active{
    margin: 3px;
    max-width: 150px;
    padding: 10px !important;
    background: #a6f0b4 !important;
    border-radius: 50%;
}
.btn-routes-save{
    margin: 3px;
    max-width: 150px;
    padding: 10px !important;
    background: #318841 !important;
    border-radius: 8px;
}

.container-routes-left{
    width: 20%;
    height: 100%;
    text-align: left;
    background-color: #f5f5f5;
    padding: 5px;
}
.container-routes-right{
    width: 80%;
    text-align: left;

}
.container-routes-right-alt{
    min-width: 100%;
    min-height: 450px;
    padding: 0px;
    border: 0.5px solid rgb(135, 135, 136);
}
.dispachtelement{
    background-color:rgb(255, 255, 255);
    border-radius: 8px;
    margin:4px;
    padding: 10px 5px 10px 5px;
    text-align:left; 
    font-size:1em;
    border: 1px solid rgb(169, 169, 169);
    &:focus{
        background-color:rgb(255, 255, 255);  
        box-shadow: 0 0 1px 5px #ccc;
    }
    &:hover{
        background-color:rgb(255, 255, 255); 
        box-shadow: 0 0 5px 5px #ccc;
    }
}
.whight10{
    max-width: 10%;
}
.whight15{
    max-width: 15%;
}
.whight30{
    max-width: 30%;
}
.whight50{
    max-width: 50%;
}
.whight65{
    max-width: 65%;
}
.subtitledispatches{
    margin:0px 4px 0px 4px;
}
.dispachtelementrow{
    display: inline-table;
    height: auto;
    width: 99%;
    justify-content: space-between;
    background-color:rgb(255, 255, 255);
    border-radius: 8px;
    margin:0px 4px 0px 4px;
    padding: 10px 5px 10px 5px;
    text-align:left; 
    font-size:1em;
    border: 1px solid rgb(169, 169, 169);
    &:focus{
        background-color:rgb(255, 255, 255);  
        box-shadow: 0 0 1px 5px #ccc;
    }
    &:hover{
        background-color:rgb(255, 255, 255); 
        box-shadow: 0 0 5px 5px #ccc;
    }
}
.dispachtelemenTitle{
    height: 60px;
    justify-content: space-between;
    border-radius: 8px;
    margin:0px 4px 0px 4px;
    padding: 10px 5px 10px 5px;
    text-align:left; 
    font-size:1em;
    background-color: ebebeb;
    
}

.dispachtItem-name{
    display: inline-grid;
    width: fit-content;
    min-width: 200px;
    margin-left: 1em;
    margin-right: 1em;  

}
.dispachtItem-id{
    background-color:#6d067c ;
    font-weight: 700;
    color: #f2f2f2;
    border-radius: 8px;
    padding: 2px;
    text-align: center; 
    width: fit-content;
    min-width: 200px;
}
.dispachtItemStatus{
    background-color:#928f8f ;
    font-weight: 700;
    color: #f2f2f2;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    width: fit-content;
}
.dispachtItemStatusIsuress{
    background-color:#dcd662 ;
    font-weight: 700;
    color: #f05151;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    width: fit-content;
}
.dispachtItemStatusSuccess{
    background-color:#2cdc3d ;
    font-weight: 700;
    color: #ffffff;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    font-size: 1em;
    width: fit-content;
}
.FmdBadIconIsuress{
    font-weight: 700;
    color: #f05151;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    width: fit-content;
}
.dispachtItem-addres{
    display: inline-grid;
    width: auto;
    max-width: 200px;
    margin-left: 1em;
    margin-right: 1em; 
}
.dispachtItem-actions{
    display: inline-flex;
    width: auto;
    max-width: 200px;
    margin-left: 1em;
    margin-right: 1em; 
}

.dispachtItem{
    display: inline-grid;
    width: auto;
    margin-left: 1em;
    margin-right: 1em;

}
.padding5{
    padding: 5px;
}
.inlineflex{
    display: inline-flex;
    right: 0px;
    margin-right: 5%;
    position: absolute;
}

.dispachtitemspan-name{
 
    max-width: 150px;
    padding: 2px;
}
.dispachtitemspan-phone{
   
    max-width: 150px;
}
.mininput {
    position:relative;
    font-size: 1em;
    max-width: 50px;
    height:  30px;
    background: linear-gradient(21deg, #dbe0e3, #ddf4ef);
    padding: 3px;
    display: inline-block;
    border-radius: 8px;
    border: none;
    outline:none;
      &:focus{
        opacity: 1;
        transform: scale(1);
        background: linear-gradient(21deg, #dbe0e3, #1beabd);
        outline:none;
      }
    }
.btn-floatright{
    float: right!important;
    outline:none!important;;
    background-color:transparent!important;
    &:focus{
        opacity: 1;
        background: linear-gradient(21deg, #dbe0e3, #1beabd)!important;
        outline:none;
      }
      &:hover{
        opacity: 1;
        background: linear-gradient(21deg, #dbe0e3, #d4d4d4)!important;
        outline:none;
      }
}
#mapsControlRoutes{
    padding: 3px; 
    background-color: rgba(255, 255, 255, 0.651);
    border-radius: 3px;
    margin-bottom: 20px;
    z-index: 3;
    min-width: 350px;
    box-shadow: rgba(48, 48, 48, 0.35) 0px 5px 15px !important;
    height: Auto !important;
    max-width: 400px;
    float: right;
    position: absolute;
    right: 3%;
    color: #000;
    top:2%;
    max-height: 700px;
    overflow: hidden;
    overflow-y: scroll;
}