.containerroutes{
    width: 100%!important;
    display: flex!important;
}
.containerroutesdiv{
    display: inline;
}
.containerroutesdivleft{
    width: 100%;
}
.containerroutesdivbox{
    width: 100%;
    height: 300px;
}
#dash-container > div > div > div > div.containerroutesdivleft > div.containerroutesdivbox.MuiBox-root.css-1tiamq4 > div > button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-aywgvy-MuiButtonBase-root-MuiIconButton-root:focus{
    outline: none!important;
}
#dash-container > div > div > div > div.containerroutesdivleft > div.containerroutesdivbox.MuiBox-root.css-1tiamq4 > div > button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-19xv45p-MuiButtonBase-root-MuiIconButton-root:focus{
    outline: none!important;
}