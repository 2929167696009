.bodycontent-traking{
max-width: 900px;
margin-left: auto;
margin-right: auto;
}

#mapsTracking{
    background-color: aliceblue;
    padding: 20px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px!important;
}
#icono-ebiex{
    max-width: 50px;
    max-height: auto;
    border-radius: 100%;
}