.field.disabled.disabledEstilo{
    color: black;
}
.formGroup3{
    max-width: 30%;
}
.formGroup2{
    max-width: 50%;
}
.adressContainer{
padding-left: 5%;
padding-right: 5%;
}
.cardUploaps{
    max-width: 100%;
    width: 291px;
    font-size: 12px;
    text-align: initial;
}