.logo-top-container {
  padding-top: auto;
  max-width: 200px !important;
}
.logo_content {
  max-width: 200px !important;
  height: auto;
  vertical-align: middle;
  margin-top: 0px;

  img {
    max-width: 100px !important;
    transition: all 0.5s;
    &:hover {
      transform: translateX(15px);
      max-width: 110px !important;
    }
  }
}

.logo_content_dash {
  max-width: 200px !important;
  height: auto;
  img {
    max-width: 150px !important;
    max-width: 150px !important;
    transition: all 0.5s;
    &:hover {
      transform: translateX(15px);
    }
  }
}

@media (max-width: 960px) {
  .logo_content {
    max-width: 100%;
    max-width: 200px !important;
    height: auto;
    img {
      max-width: 150px !important;
      transition: all 0.5s;
      &:hover {
        transform: translateX(-15px);
      }
    }
  }
  .logo_content_dash {
    max-width: 200px !important;
    height: auto;
    margin-top: 0px;
    img {
      max-width: 150px !important;
      max-width: 150px !important;
      transition: all 0.5s;
      &:hover {
        transform: translateX(-15px);
      }
    }
  }
}

@media (max-width: 767px) {
  .logo_content {
    max-width: 100%;
    max-width: 78px !important;
    height: auto;
    margin: 0px;
    img {
      max-width: 78px !important;
      transition: all 0.5s;
      &:hover {
        transform: translateX(-15px);
      }
    }
  }
  .logo_content_dash {
    max-width: 200px !important;
    height: auto;
    margin-top: 0px;
    img {
      max-width: 78px !important;
      transition: all 0.5s;
      &:hover {
        transform: translateX(-15px);
      }
    }
  }
}
