.bodypost {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.bodypostcontentsecion {
  margin-left: 0%;
  margin-right: 0%;
  // border: 1px solid rgb(0, 4, 255);
  min-height: 100%;
  background-color: transparent;
}
.toptitlecontainer {
  padding-top: 10%;
}
.bodytitlecontainer {
  text-align: center;
  padding-top: 15%;
}

.bodypostsecion {
  min-height: 450px;
  background-color: #fff;
  padding: 1px;
}
.bodypostsecionContainer {
  margin-left: 5%;
  margin-right: 5%;
  min-height: 450px;
  background-color: #fff;
  padding: 1px;
  margin: auto;
  max-width: 1200px;
  margin-bottom: 100px;
  padding-bottom: 100px;
}
.imgLogoFlota {
  position: absolute;
  left: 0px;
  width: 100vh;
}
.imgLogoFlotaLM {
  position: absolute;
  left: 0px;
  max-width: 450px;
}
.imgLogoFlotaRetail {
  position: absolute;
  left: 0px;
  max-width: 450px !important;
}

.goostyle {
  padding: 5%;
  text-align: center;
  color: #fff;
}
.titleflota {
  color: #fff;
  font-size: 28px;
}
.titleflotaBody {
  .titleflota {
    color: rgb(41, 41, 41);
    font-size: 18px;
  }
}

section {
  width: 100%;
  min-height: 500px;
  padding-bottom: 2%;
}

.pattern {
  position: relative;
  background-color: #741aaf;
  background-image: linear-gradient(315deg, #741aaf 0%, #741aaf 100%);
  margin-bottom: 1%;
}

// .pattern:before {
//   content: "";
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 50%;
//   background: url("/static/images/asset/wave_b.png");
//   background-size: cover;
//   background-repeat: repeat;
// }
// .pattern3:before {
//   content: "";
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 30%;
//   background: url("/static/images/asset/wave_m.png");
//   background-size: cover;
//   background-repeat: repeat;
// }
.pattern2 {
  position: relative;
}
// .pattern2:before {
//   content: "";
//   position: absolute;
//   bottom: 10%;
//   left: 0;
//   width: 100%;
//   height: 50%;
//   background: url("/static/images/asset/Lines-01.svg");
//   background-size: cover;
//   background-repeat: no-repeat;
// }

.typeMiniformsRider {
  margin: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  min-height: 400px !important;
}
.stylestypeMiniformsRider {
  text-align: center !important;
}

.typeMiniformsRidertitle1 {
  font-size: 30px;
  font-weight: 600;
  color: #591e8f;
}
.typeMiniformsRidertitle2 {
  font-size: 15px;
  font-weight: 900;
  color: #591e8f;
}

.typeMiniformsRidertext1 {
  font-size: 16px;
  letter-spacing: -0.85px;
  font-weight: 400;
}
.typeMiniformsCard {
  text-align: left;
}
.formcontentflota {
  text-align: left;
}
.formGroupStyleCurve .ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;

  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: #f2f2f2 !important;
  color: fieldtext !important;
}
.formGroupStyleCurve {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;

  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
}
#imputSearchFromToCurve {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;
  margin-bottom: 5px;
  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  margin: 0 0 1em;
  padding: 2%;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
}
.search-box-ebiexCurve {
  display: block;
  width: 100% !important;
  padding-left: 1%;
}
#imputSearchFromToCurvel {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;

  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
  max-height: 40px !important;
}
#imputSearchFromToCurvel2 {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;

  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
  max-height: 40px !important;
}
#imputSearchFromToCurvel3 {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;

  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
  max-height: 40px !important;
}
#imputSearchFromToCurvetd {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 100%;
  outline: none;
  -webkit-padding-end: 44px;
  padding-inline-end: 44px;
  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
  max-height: 40px !important;
}
.imputSearchFromToCurvetdspace {
  min-width: 25%;
}
.comboboxList-ebiex-from {
  padding-top: 5px;
  width: max-content;
}
@media only screen and (max-width: 780px) {
  .bodypostsecionContainer {
    margin-left: 1%;
    margin-right: 1%;
    min-height: 450px;
    background-color: #fff;
    width: 98%;
    padding: 15px;
  }
  .search-box-ebiexCurve {
    display: block;
    width: 100% !important;
    margin: 0 0 1em;
    padding: 2% !important;
  }
  .imputSearchFromToCurvetdspace {
    min-width: 100%;
    margin: 0 0 1em;
    padding: 2%;
  }
  .formGroup2 {
    min-width: 100% !important;
    width: 100% !important;
  }
  .formGroup3 {
    min-width: 100% !important;
    width: 100% !important;
  }
  .formGroup4 {
    min-width: 100% !important;
    width: 100% !important;
  }
}
