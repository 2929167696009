#micuentafromIni {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: auto;
  padding-bottom: 10px;
}
#mi-cuentaContent {
  // justify-content: center;
  margin: auto;
  padding-bottom: 50px;
}
.typeMiniforms {
  margin-top: 5px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  border-radius: 3px !important;
}
.test {
  max-width: 50px;
}

@media only screen and (max-width: 950px) {
  .typeMiniforms {
    margin-top: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px !important;
    border-radius: 3px !important;
  }
}
