#servicioscontent {
  max-width: 100%;
  width: 100%;
  text-align: left;
  margin: auto;
  padding-bottom: 50px;
}
.backcgroundcolorDev {
  background-color: #fff;
}
.textalignend {
  text-align: end;
}
.typeMiniformsserv {
  max-width: 650px;
  margin: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px !important;
  height: max-content;
}

.distproductos {
  background-color: #fff;
  display: flex;
  padding: 18px;
  position: relative;
}

.distproductosdescrip {
  border: 1px solid #e9ecef;
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  padding: 18px;
  position: relative;
}
#dash-container .dash-container-colmun {
  border: none;
  box-shadow: none;
  width: 100%;
}
.dash-container-colmun-equal {
  width: 33%;
}
.btncambiar {
  color: #1a82e2;
  background-color: #fff;
  border-color: #489be8 !important;
  border: 1px solid;
  :hover {
    color: #1a82e2;
    background-color: #e8f2fc;
  }
  div {
    padding: 6px 18px;
  }
}

.maxw {
  max-width: 100px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #14b8a6;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.centervertical {
  align-items: center;
}
.divmap {
  width: 100% !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}
.h5 {
  font-weight: 600;
  font-size: 15px;
}
.colmunPd30 {
  max-width: 30% !important;
}
.colmunPd70 {
  max-width: 70% !important;
}
.priceul {
  text-decoration: none;
  list-style: none;
  color: rgb(141, 141, 141);
  text-align: end;
}
