.imboxmessages{
    display: flex!important;
}

.listmessagescontainer{
    width: -webkit-fill-available!important;
    margin-left: 3px;
}
.ui.vertical.menu {
    width: 15rem;
    min-width: 15rem;
}
.Btn-rowCell{
    cursor: pointer!important;
    padding-bottom: 10px;
    padding-top: 10px;
    -webkit-box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
    box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
}

.Btn-rowCell-read{
    cursor: pointer!important;
    padding-bottom: 10px;
    padding-top: 10px;
    -webkit-box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
    box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
}

.Btn-rowCell-openend{
    cursor: pointer!important;
    position: relative;
    color: #202124;
    background: #efefef;
    -webkit-box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
    box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%);
}

.Btn-rowCell:hover , .Btn-rowCell-openend:hover{
    box-shadow: inset 1px 0 0 #dadce0,inset -1px 0 0 #dadce0,0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15);
}
.displayFlex{
    display: flex;
}
.buttonRowMjs{
    background-color: unset;
    padding: 0px;
}