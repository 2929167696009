.resaltadoCurvo {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 400;
  text-decoration: wavy;
  font-style: italic;
}

@media only screen and (max-width: 760px) {
  .react-player {
    height: 375px !important;
  }
}
@media only screen and (min-width: 761px) and (max-width: 950px) {
  .react-player {
    height: 550px !important;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1140px) {
  .react-player {
    height: 650px !important;
  }
}
