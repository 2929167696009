.colorGreen {
  background-color: rgb(16, 172, 16) !important;
}

.background-red-color {
  background-color: #fb1429;
}

.fontf8 {
  color: #f8f8f8;
}
.formcontenttools {
  display: inline-block;
}

.typeMiniformsBars {
  background-color: #ffffff;
  color: #121828;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  overflow: hidden;
  align-items: lef;
  display: inline-block;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  margin: 5px;
  padding: 3px;
  min-width: 200px;
  max-height: 200px;
  width: 220px;
  height: 220px;
}
.typeMiniformsBarsMetricsTool {
  background-color: #ffffff;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
    0px 1px 2px rgb(100 116 139 / 10%);
  overflow: hidden;
  -webkit-align-items: lef;
  -webkit-box-align: lef;
  -ms-flex-align: lef;
  align-items: lef;
  display: inline-block;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  margin: 5px;
  padding: 3px;
  min-width: 200px;
  max-height: 200px;
}
.typeMiniformsBarsMetrics {
  background-color: #ffffff;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
    0px 1px 2px rgb(100 116 139 / 10%);
  overflow: hidden;
  -webkit-align-items: lef;
  -webkit-box-align: lef;
  -ms-flex-align: lef;
  align-items: lef;
  display: inline-block;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  padding: 3px;
  width: 100%;
}

.typeMiniformsBarsAccions {
  background-color: #ffffff;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
    0px 1px 2px rgb(100 116 139 / 10%);
  overflow: hidden;
  -webkit-align-items: lef;
  -webkit-box-align: lef;
  -ms-flex-align: lef;
  align-items: lef;
  display: flex;

  margin: 5px;
  border: 1px solid #cbcbcb;
  border-radius: 8px;
  min-height: 180px;
}
.typeMiniformsBarsAccionsItems {
  // min-height: 180px;
  // width: 350px;
  // max-width: 250px;
  // min-width: 200px;
  // padding: 0px;
  // border-radius: 8px;
  // box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
  //   0px 1px 2px rgb(100 116 139 / 10%);
  background-color: #ffffff;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%),
    0px 1px 2px rgb(100 116 139 / 10%);
  overflow: hidden;
  -webkit-align-items: lef;
  -webkit-box-align: lef;
  -ms-flex-align: lef;
  align-items: lef;
  display: inline-block;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  margin: 5px;
  padding: 3px;
  min-width: 200px;
  max-height: 200px;
  width: 200px;
  height: 200px;
}
.headerContent {
  justify-content: space-between;
}
.extentButton {
  width: 100% !important;
  max-width: 150px !important;
}
.fontsize25 {
  width: 25px;
  height: 25px;
}
.fontsize18 {
  font-size: 18px;
  font-weight: 700;
}
.frontMetricBar {
  // display: flex;
  width: 100%;
  padding: 2px;
}

.btn-add-guide-small {
  padding: 10px;
  background: #591e8f !important;
  display: inline-block !important;
  padding: 5px 5px 5px 5px !important;
  border-radius: 4px !important;
  color: #f8f8f8;
  font-size: 12px;
  font-weight: 300;
  margin: 1px;
  line-height: 1;
}
.btn-add-guide-small-cancel {
  color: rgb(255, 255, 255);
  background-color: rgb(213, 24, 43);
  padding: 10px;
  display: inline-block !important;
  padding: 5px 5px 5px 5px !important;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 300;
  margin: 1px;
  line-height: 1;
}

.typeMiniformsBarsMetricsToolBar {
  max-width: 207px;
}
.minimalTd {
  min-width: 152px !important;
}

.typeMiniformsBarsMetricsToolBar {
  max-width: 207px;
}
.minimalTd {
  min-width: 152px !important;
}

.switch-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.switch-wrapper {
  display: flex;
  align-items: flex-end;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-right: 1rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #ffffff !important;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #30dd72;
}

input:focus + .slider {
  box-shadow: 0 0 1px #30dd72;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.realtime-label {
  font-weight: bold;
}

.realtime-info {
  font-size: 0.9rem;
}

.realtime-pause {
  margin-top: 0.5rem;
}

.btn-find {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-find:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-find:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-find:disabled {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-find:not(:disabled):not(.disabled):active,
.btn-find:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0062cc;
  border-color: #005cbf;
}

.btnsearchd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  transition: background-color 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btnsearchd:hover {
  background-color: #d9d9d9;
}

.btnsearchd:active {
  background-color: #bfbfbf;
}

.btnsearchd:focus,
.btnsearchd.focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.5);
  outline: none;
}
.progressValuesNum {
  font-size: 40px;
  font-weight: 600;
}
@media only screen and (min-width: 951px) and (max-width: 1230px) {
  .frontMetricBar {
    display: inline;
  }
  .content-small-screm {
    width: 100%;
  }
  .typeMiniformsBarsMetricsToolBar {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    max-width: 100%;
  }
  .typeMiniformsBarsMetricsTool {
    background-color: #ffffff;
    color: #121828;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
      0px 1px 2px rgba(100, 116, 139, 0.1);
    display: inline-block;
    border: 1px solid #cbcbcb;
    margin: 2px;
    padding: 3px;
    max-height: 200px;
    height: 200px;
    width: 100%;
    min-width: unset;
    overflow: unset;
  }
}
@media only screen and (max-width: 950px) {
  .formcontenttools {
    display: inline-block;
  }
  .typeMiniformsBars {
    width: 100% !important;
    background-color: #ffffff;
    color: #121828;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06),
      0px 1px 2px rgba(100, 116, 139, 0.1);
    overflow: hidden;
    align-items: lef;
    display: inline-block;
    flex-direction: column;
    border: 1px solid #cbcbcb;
    margin: 5px;
    padding: 3px;
    min-width: 200px;
    max-height: 200px;
  }
  .frontMetricBar {
    display: inline-block;
    width: 100%;
    padding: 2px;
  }
  .typeMiniformsBarsMetricsToolBar {
    max-width: 207px;
    display: contents;
  }
  .typeMiniformsBarsMetricsTool {
    width: 100% !important;
  }
  .typeMiniformsBarsMetricsToolBar.div.div.h3 {
    font-size: 0.8em !important;
  }
  .switch-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .realtime-label {
    margin-top: 0;
  }

  .realtime-info {
    margin-top: 0;
  }

  .realtime-pause {
    margin-top: 0;
  }

  .searcher-wrapper {
    margin-top: 0;
  }
}
