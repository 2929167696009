.table-card {
  display: block;
  width: 100%;
  overflow-x: auto;
}
#inputstilesforce {
  max-width: 250px;
  display: inline-block;
  margin: 3px;
}
