.carousel-container {
  max-height: 300px;
  background-color: #fff;
}
.carousel-container {
  max-height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: #fff;
  color: #fff;
}
.carousel-container::-webkit-scrollbar {
  width: 0.1px;
  background-color: #fff;
  color: #fff;
}

.carousel-container::-webkit-scrollbar-track {
  background-color: #fff;
  color: #fff;
}

.carousel-container::-webkit-scrollbar-thumb {
  background-color: #fff;
  color: #fff;
}
.carousel-item {
  width: 100px !important;
  display: inline-block !important;
}
.componente1 .slick-track {
  width: 3300px !important;
  height: 110px !important;
  background-color: #fff;
}
.slick-slide {
  display: inline-block !important;
  width: 120px !important;
  height: 200px !important;
  overflow: hidden;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #1e2158;
  border: solid #1e2158 2px;
  border-radius: 14px;
  margin: 10px;
  -webkit-box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
}

.button-icon {
  margin-bottom: 10px;
  color: #ededed;
}

.button-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ededed;
}

@media only screen and (max-width: 950px) {
  .carousel-container {
    max-height: 300px;
    background-color: #fff;
  }
  .carousel-container {
    max-height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: #fff;
    color: #fff;
  }
  .carousel-container::-webkit-scrollbar {
    width: 0.1px;
    background-color: #fff;
    color: #fff;
  }

  .carousel-container::-webkit-scrollbar-track {
    background-color: #fff;
    color: #fff;
  }

  .carousel-container::-webkit-scrollbar-thumb {
    background-color: #fff;
    color: #fff;
  }
  .carousel-item {
    width: 100px !important;
    display: inline-block !important;
  }
  .componente1 .slick-track {
    width: 3300px !important;
    height: 110px !important;
    background-color: #fff;
  }
  .slick-slide {
    display: inline-block !important;
    width: 120px !important;
    height: 200px !important;
    overflow: hidden;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: #1e2158;
    border: solid #1e2158 2px;
    border-radius: 14px;
    margin: 10px;
    -webkit-box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
  }

  .button-icon {
    margin-bottom: 10px;
    color: #ededed;
  }

  .button-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #ededed;
  }
}

// .item {
//   width: 30px !important;
//   height: 30px !important;
//   display: inline-block;
//   border: solid 1px #2801d8;
//   padding: 5px;
//   border-radius: 15px;
//   margin: 2px;
// }

// .item.item-content {
//   background: #5f9ea0;
//   color: #fff;
//   font-size: 36px;
//   line-height: 100px;
//   margin: 1px;
//   padding: 1%;
//   position: relative;
//   text-align: center;
// }
