#codigoBarras.barcode-input {
  margin-top: 3px;
  border: none !important;
  padding: 5px;
  font-family: "Code 128", Arial, sans-serif; /* Fuente similar a la de códigos de barras */
  letter-spacing: 4px; /* Espacio entre caracteres */
  background: #fff;
  width: 100%;
  outline: none;
  text-align: center;
}
#codigoBarras.barcode-input:focus {
  margin-top: 3px;
  border: none !important;
  padding: 5px;
  font-family: "Code 128", Arial, sans-serif; /* Fuente similar a la de códigos de barras */
  letter-spacing: 4px; /* Espacio entre caracteres */
  background: #fff;
  width: 100%;
  outline: none;
  text-align: center;
}
#codigoBarras.barcode-input:active {
  margin-top: 3px;
  border: none !important;
  padding: 5px;
  font-family: "Code 128", Arial, sans-serif; /* Fuente similar a la de códigos de barras */
  letter-spacing: 4px; /* Espacio entre caracteres */
  background: #fff;
  width: 100%;
  outline: none;
  text-align: center;
}
#codigoBarras.barcode-input::before {
  margin-top: 3px;
  margin-right: 4px;
  border: none !important;
  padding: 5px;
  font-family: "Code 128", Arial, sans-serif; /* Fuente similar a la de códigos de barras */
  letter-spacing: 4px; /* Espacio entre caracteres */
  background: #fff;
  width: 100%;
  outline: none;
  text-align: center;
}
#codigoBarras.barcode-input:focus-visible {
  margin-top: 3px;
  margin-right: 4px;
  border: none !important;
  padding: 5px;
  font-family: "Code 128", Arial, sans-serif; /* Fuente similar a la de códigos de barras */
  letter-spacing: 4px; /* Espacio entre caracteres */
  background: #fff;
  width: 100%;
  outline: none;
}

#codigoBarras.barcode-input-keyboar {
  margin-top: 3px;
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: rgb(235, 235, 235);
  font-size: inherit;
  height: 87%;
  outline: none;
  padding-inline-end: 44px;
  position: relative;
  padding: 5px;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: #f2f2f2 !important;
  color: fieldtext !important;
  text-align: center;
}

.searchtiketcontainers {
  padding-left: auto;
  padding-right: auto;
  background-color: #f8f8f8;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  top: 0px;
  height: 100%;
  min-height: 650px;
}

.tiketcontainers {
  overflow-y: auto;
  min-height: 650px;
  max-height: 650px;
  background-color: #f2f2f2;
}
#inputcode {
  padding: 10px;
}

.alertcolor {
  width: 100%;
  margin: 0px;
  padding: 4px !important;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px #88db67;
  color: #000;
  font-size: 1.5em !important;
}
.alertRed {
  width: 100%;
  margin: 0px;
  padding: 4px !important;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 5px 5px #db6b67;
  color: #000;
  font-size: 1.5em !important;
}
