.cardcontentrpute {
  justify-content: space-between;
  display: flex;
}
.FormGroupVehicle {
  margin: 3px;
}
.FormGroupRiders {
  margin: 3px;
}
.alert {
  width: 100%;
  margin: 0px;
  padding: 2px !important;
  position: relative;
  border-radius: 5px;
  box-shadow: 10px 10px 0px -9px rgba(0, 0, 0, 0.75);
  color: #141212;
  font-size: 8px !important;
}
.pingalert {
  width: 100%;
  margin: 0px;
  padding: 2px !important;
  position: relative;
  border-radius: 5px;
  box-shadow: 10px 10px 0px -9px rgba(0, 0, 0, 0.75);
  color: #141212;
  font-size: 8px !important;
}
.danger-alert,
.alert-danger {
  background-color: #ca1a10 !important;
  border-left: 1px solid #8f130c !important;
  width: auto; /* Ajusta el ancho según el contenido */
  max-width: 100%; /* Opcional: limita el tamaño */
  color: #ffffff !important;
  padding: 5px 10px !important; /* Ajusta el padding */
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  font-size: 10px !important; /* Ajustado para mejor legibilidad */
  white-space: nowrap; /* Evita saltos de línea */
}

.routeResumen {
  width: 100% !important;
}
.resimanconfirmCancel {
  width: 100%;
  background-color: #e48d1c;
  margin-top: 5px;
  margin-bottom: 5px;
}
