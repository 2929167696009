div.footerScss {
  max-height: 100px !important;
  border-top-color: #fff;
  border: solid;
  background: #1e2158;
  /* background: linear-gradient(90deg, #591e8f 0%, rgb(73, 9, 121) 37%, rgb(0, 205, 246) 100%); */
  height: 25px;
  width: 100%;
  text-align: end;
  z-index: 9000;
  position: fixed;
  bottom: 0px;
  margin-bottom: 0px;
  justify-content: center;
}
.copyright {
  font-size: 10px;
  text-align: center;
  color: #f2f2f2;
  padding: 3px;
}
