.bodycontent-pricing {
  padding: 5px;
  margin-bottom: 30px;
}
.cardproduct {
  margin: auto;
  margin-bottom: 20px;
  background-color: #3bcddc(60, 247, 253) !important;
  color: rgb(168, 0, 235) !important;
  -webkit-box-shadow: 5px 5px 15px -7px #000000 !important;
  box-shadow: 5px 5px 15px -7px #000000 !important;
  text-align: center !important;
  min-width: 250px !important;
}
.rowproduct {
  margin-top: 20px;
  margin-bottom: 30px;
}

.header-info {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  font-size: 20px;
  color: #ffffff;
  background-color: #7d0ee6;
  border-color: #7d0ee6;
  text-align: center;
}
.section-title {
  text-align: center;
  h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
    margin-top: 20px;
    background: #e7f1fd;
    color: #7d0ee6;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    text-align: center !important;
  }
  h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;
    color: #7d0ee6;
  }
  p {
    margin: 15px auto 0px auto;
    font-weight: 600;
    color: #7d0ee6;
  }
}
.css-16zn6n4-MuiTypography-root {
  font-size: 36px !important;
  color: #7d0ee6 !important;
  font-weight: 600 !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  margin-bottom: 20px !important;
  text-align: center !important;
  h4 {
    font-size: 36px;
  }
}
.price-size {
  font-size: 36px !important;
  color: #7d0ee6 !important;
  margin: 0px;
  span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
}
.price-size-minimal {
  font-size: 18px !important;
  color: #7d0ee6 !important;
  margin: 0px;
  span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
}
.price-size-mediun {
  font-size: 20px !important;
  color: #7d0ee6 !important;
  margin: 0px;
  span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
}
.price-size-tach {
  text-decoration: line-through;
  color: #e60e56 !important;
  margin: 0px;
  span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
}
li.ro {
  color: #7d0ee6 !important;
  text-decoration: underline !important;
  list-style: none !important;
}
.btn-wrap {
  margin: auto !important;
  padding: 20px 15px !important;
  text-align: center !important;
}

.btn-buy {
  background: #7d0ee6 !important;
  display: inline-block !important;
  padding: 8px 35px 10px 35px !important;
  border-radius: 4px !important;
  color: #fff !important;
  transition: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  transition: 0.3s !important;
}
.backgroundfooter {
  background: #f8f8f8;
}
