@media only screen and (min-width: 992px) {
  #socialcontainer {
    padding: 4%;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
    margin-top: -1.25rem;
    height: 10vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    a {
      color: #591e8f;
      text-decoration: none;
      background-color: transparent;
    }
  }
  a.btn-bicci-prupura-color {
    color: #591e8f;
    text-decoration: none;
    background-color: transparent;
  }
  .navbarbici {
    -webkit-box-shadow: 3px 10px 16px -16px #ffffff;
    box-shadow: 3px 10px 16px -16px #ffffff;
    width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    top: 0px;
    z-index: 10;
  }
  #socialcontainer > button:focus {
    outline: none;
  }

  #navbarconntainer {
    padding-top: 0px !important;
    padding-bottom: 1% !important;
    justify-content: space-between;
    z-index: 3;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: auto !important;
    padding-right: auto !important;
    max-width: 1140px !important;
    max-height: 80px;

    &__left {
      display: flex !important;
      align-items: center;
      padding-left: 1em;
      padding-right: 1em;

      z-index: 4;
      .column {
        width: 100% !important;
      }
    }

    &__rigth {
      display: flex !important;
      align-items: center;
      justify-content: flex-end;
      padding-left: 1em;
      padding-right: 1em;
      z-index: 4;

      div {
        padding-top: 5px;
      }
    }
    .navbarNaw {
      width: 100%;
      justify-content: space-between;
      margin-top: 0px !important;
      padding-top: 0px !important;
      margin-right: 0px;
      margin-left: 0px;
      max-width: 1140px;
    }

    .navbari__rigth {
      text-align: end;
    }
    .DirectionBtn {
      display: flex;
      margin-top: 15px;
    }
    .PointBtn {
      font-size: 2em;
      padding-top: 3px;
    }
    .DirBtn {
      font-size: 12px;
    }
  }
}

@media (max-width: 920px) {
  .navbarNaw {
    width: 100%;
    justify-content: space-between;
    margin-top: 0px;
    padding-top: 0px;
    margin-right: 10px;
    margin-left: 10px;
  }
  a.btn-bicci-prupura-color {
    color: #591e8f;
    text-decoration: none;
    background-color: transparent;
  }
  div.column.navbar__left {
    margin-left: 0px;
    margin-top: 10px;
  }
  div.column.navbar____rigth {
    display: none;
  }
  #navbarconntainer {
    justify-content: space-between;
    z-index: 3;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: auto !important;
    padding-right: auto !important;
    max-width: 1140px !important;
    max-height: 80px;
    &__left {
      width: 100% !important;
      margin-left: 0px;
    }
    &__right {
      width: 100% !important;
      display: none;
    }
  }
}

@media (max-width: 767px) {
  div.column.navbar__left {
    margin-left: 0px;
    margin-top: 10px;
  }
  a.btn-bicci-prupura-color {
    color: #591e8f;
    text-decoration: none;
    background-color: transparent;
  }
  #navbarconntainer {
    justify-content: space-between;
    z-index: 3;
    background-color: #fff;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: auto !important;
    padding-right: auto !important;
    max-width: 1140px !important;
    max-height: 80px;
    &__left {
      width: 100% !important;
      margin-left: 22px;
    }
    &__right {
      width: 100% !important;
    }
  }
}
.navbarbici {
  width: 100%;
  position: fixed;
  background-color: #fff !important; //rgba(255, 255, 255, 0.9)!important;
  z-index: 10;
  padding-top: 0px !important;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  top: 0px;
}
a.btn-bicci-prupura-color {
  color: #591e8f;
  text-decoration: none;
  background-color: transparent;
}
.navbarNaw {
  margin: auto !important;
}
