.DashIconsResumenBoxCard {
  background-color: rgb(255, 255, 255);
  color: rgb(18, 31, 67);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px !important;
  border-radius: 8px;
  padding: 18px;
  height: 100%;
  display: inline-block;
  margin-left: 5px;
}

.DashIconsResumenBoxHeader {
  display: inline-block;
}

.DashIconsResumencard02 {
  padding: 3px;

  //min-width: 23%!important;
  //width:25%!important;
}
.dashIconTable {
  color: blue;
  position: absolute;
  font-size: 5em !important;
  float: left;
  padding: 15px;
  margin-top: -50px;
  margin-right: 15px;
  border-radius: 3px;
  background-color: #999;
  background: linear-gradient(60deg, #c8c6c3, #c8b49c);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgba(252, 252, 251, 0.4);
}
.truckDash {
  color: #f8f8f8;
  position: absolute;
  font-size: 5em !important;
  float: left;
  padding: 15px;
  margin-top: -50px;
  margin-right: 15px;
  border-radius: 3px;
  background-color: #999;
  background: linear-gradient(60deg, hwb(278 13% 22%), #7f0f95);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgba(212, 0, 255, 0.4);
}
.groupUser {
  color: #7f0f95;
  position: absolute;
  font-size: 5em !important;
  float: left;
  padding: 15px;
  margin-top: -50px;
  margin-right: 15px;
  border-radius: 3px;
  background-color: #999;
  background: linear-gradient(60deg, hwb(278 74% 9%), #e6e2e7);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgba(212, 0, 255, 0.4);
}
.dashIconsresumencardcontent06 {
  text-align: center;
  padding: 0px !important;
}
.dashicontitled {
  padding: 3px;
  margin: 3px;
}

.dashIcon-blue {
  color: blue;
}
.dashIcon-grey {
  color: rgb(158, 158, 158);
}
.dashIcon-green {
  color: rgb(173, 231, 173);
}
.dashIcon-on-line {
  color: rgb(17, 240, 103);
}
.dashIcon-red {
  color: rgb(240, 50, 17);
}
.dashicontspancustontext {
  padding: 1px;
  font-size: 2em;
}
.dashicontspansmalltext {
  padding: 1px;
  font-size: 0.7em;
}
@media only screen and (max-width: 950px) {
  .DashIconsResumenBoxCard {
    background-color: rgb(255, 255, 255);
    color: rgb(18, 31, 67);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px !important;
    border-radius: 8px;
    padding: 18px;
    height: 100%;
    display: inline-block;
    margin: 5px;
    margin-top: 35px;
    width: 90%;
  }
  .rowmovil {
    max-width: 99% !important;
    margin: auto !important;
  }
}
