.tabs-titles {
  list-style: none;
  padding: 0px;
  margin: 0;
}

.color-blue {
  color: #0023e7;
}

.tab-title {
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  color: #c7c6c2;
  cursor: pointer;
  margin-left: 1px;
}

.tab-title--active {
  background-color: #f5f5f5;
  color: #00070a;
}

.tab-content {
  background-color: #f5f5f5;
  padding: 5px;
  margin: 0;
}

#imgavatar {
  max-width: 75px;
  border-radius: 100%;
}

#imgeApelations {
  max-width: 75px;
  border-radius: 15%;
}
.imgavatar {
  max-width: 75px;
  border-radius: 15px;
  margin: 3px;
}

#containerDetails {
  width: auto;
  margin-left: 0px;
  margin-right: 0px;
}

.ui.secondary.menu {
  overflow-x: auto;
  white-space: nowrap;
}
