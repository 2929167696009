.contariner-view-email {
  width: 100%;
  text-align: left;
  padding: 20px;
  background-color: #fff;
}
.formcontent-email {
  background-color: aliceblue;
  min-height: 350px;
  width: 100%;
  margin-left: 0px;
  width: 100%;
  margin-right: 0px;
}
.emailbodycontainer {
  padding: 20px;
  text-align: justify;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.6;
}
.emailbodycontainer-mensaje {
  margin-top: 30px;
  margin-bottom: 20px;
}
