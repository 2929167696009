#smallspinnerloading {
  width: auto !important;
  height: auto !important;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

#smallspinnerloadingtransparent {
  width: auto !important;
  height: auto !important;
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  opacity: 0.9; /* Control de transparencia */
  backdrop-filter: blur(10px); /* Aplicar desenfoque */
  -webkit-backdrop-filter: blur(10px); /* Soporte para Safari */
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-text-opacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-container,
.loading {
  height: 124px;
  position: relative;
  width: 124px;
  border-radius: 100%;
}

.loading-container {
  margin: 40px auto;
}

.loading {
  border: 4px solid transparent;
  border-color: transparent #591e8f transparent #591e8f;
  -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
  -moz-transform-origin: 50% 50%;
  -o-animation: rotate-loading 1.5s linear 0s infinite normal;
  -o-transform-origin: 50% 50%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  transform-origin: 50% 50%;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 18px -7px rgba(250, 250, 250, 1);
  -moz-box-shadow: 10px 10px 18px -7px rgba(250, 250, 250, 1);
  box-shadow: 10px 10px 18px -7px rgba(250, 250, 250, 1);
}

.loading-container:hover .loading {
  border-color: transparent #3bcddc transparent #e45635;
}
.loading-container:hover .loading,
.loading-container .loading {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#loading-text {
  -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
  -o-animation: loading-text-opacity 2s linear 0s infinite normal;
  -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
  animation: loading-text-opacity 2s linear 0s infinite normal;
  color: #fff;
  font-family: "Helvetica Neue, " Helvetica ", " "arial";
  font-size: 14px;
  font-weight: 600;
  margin-top: 45px;
  opacity: 0;
  text-align: center !important;
  text-transform: uppercase;
  top: 0;
  left: 0px;
  right: 0px;
  width: 100px !important;
  margin-left: auto;
  margin-right: auto;
}
#logoSpinnerLoading {
  color: #591e8f;
  font-family: "Helvetica Neue, " Helvetica ", " "arial";
  font-size: 10px;
  font-weight: bold;
  opacity: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100px;
  opacity: 1;
}
.loadingAbsolute {
  position: absolute;
  top: 40px;
  left: 10px;
  transform: translate(-50%, -50%);
  text-align: center;
}
