#pagetitle {
  text-align: center !important;
}
#pagetitle.h2 {
  font-size: 23px;
  font-weight: 700px;
  color: #591e8f;
  text-align: center;
}

.ui.celled.grid > .row {
  box-shadow: 0 -1px 0 0 #ffffff;
}
#Buscadorletf {
  align-self: center;
  text-align: right;
  font-size: 22px;
  font-weight: 700px;
  color: #591e8f;
  text-transform: uppercase;
  border: none;
}

#Buscadorright {
  align-self: center;
}

#paragraph1 {
  align-self: center;
  align-self: center;
  padding: 10px;
}
#paragraph2 {
  align-self: center;
  align-self: center;
  padding: 10px;
}

#paragraph3 {
  align-self: center;
  align-self: center;
  padding: 10px;
}
#paragraph4 {
  align-self: center;
  align-self: center;
  padding: 10px;
}
@media only screen and (max-width: 950px) {
  #Buscadorletf {
    align-self: center;
    text-align: center;
    font-size: 22px;
    font-weight: 700px;
    color: #591e8f;
    text-transform: uppercase;
    border: none;
  }
  #Buscadorright {
    align-self: center;
    text-align: center;
  }
}
