.video-containersup{
    top:-300px;
    .header-overlay{
        height:550px;
        width:100%;
        background-color: #57096b;
        opacity: .35;
        position: absolute;
        z-index: 1;
    }


    #video-container00{
        width: 100% !important;
        max-height: 550px;
        overflow: hidden;
    }
    .bottonInicio{
        position: absolute;
        top: 300px;
        width: 100%;
        text-align: center;
        z-index: 1;
        .tituloInicio{
            color: $blanco;
            padding: 5px;
        }
        .bottonInicioBtn {
            color: $blanco;
            background-color: $calipso;
            padding: 15px 50px 15px 50px;
            border-radius: 30px 30px 30px 30px;
        }
    }
}
@media (max-width: 767px){
    #video-container00{
        width: 100% !important;
        max-height: 250px !important;
        overflow: none;
        display: none;
    }
    .video-containersup .header-overlay{
        height: 0px;
    }
    .header-overlay{
        display: none;
    }
    .bottonInicio{
        display: none;
    }
}