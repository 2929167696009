div.containerBannerfooter{
    border-radius: 30px;
    width: 100%;

    .ui.image{
        width: 100%;
    }
}

.FloatRignt{
    position: absolute;
    right: 20%;
    max-height: 100%;
    overflow-y: auto;
    max-width: 350px;
    color: #fff;
    z-index: 3;
    margin-top: 5%;
}

.FloatLeft{
    position: absolute;
    left: 31%;
    max-height: 350px;
    overflow-y: auto;
    max-width: 350px;
    color: #fff;
    z-index: 3;
    margin-top: 5%;
}


@media only screen and (max-width: 760px){
div.containerBannerfooter{
margin-top: 0px;
}
.FloatRignt{
    margin-bottom: 20px;
    position: static;
    display: block;
    color: #591e8f;
    z-index: 3;
    max-width: 350px;


}
.marging20{
    margin: 20px;
}

}
