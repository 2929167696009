.dashcontent{
    min-width: 100%;
    min-height: 650px;

}
.dash-container-colmun{
    padding: 5px;
}
.card-ultimasOrdenes{
border-radius: 3px;
box-shadow: 0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%);
}
.dashcontentDashIconsResumen{
    display: inline-flex;
    padding-top: 20px;
}
.DashIconsResumen50{
    width: fit-content;
    margin: 1px;
}
.DashIconsResumen30{
    width: fit-content;
    margin: 1px;
}
.DashIconsResumen20{
    width: fit-content;
    margin: 1px;
}