.metricscontainer{
    display: block;
    margin-bottom: 24px;
    padding: 3px;
    border: 1px solid;
    background-color: #f1f1f1;
}
.metricscontainerSub{
    font-weight: 600;
    font-style: oblique;
}
.boxmetrics{
    float: right;
    padding: 10px;
}
.panel-horizontal{
    display: inline;
    width: 100%;
    }

.panel-vertical{
    margin-top: 1%;
    margin-left: 1%;
    padding: 5px;
    display: block;
    border: solid 1px rgb(223, 220, 220);
    width: 300px;
    height: fit-content;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.86);
    z-index: 10;
    border-radius: 3px;
    max-height: 700px;
    overflow: hidden;
    overflow-y: scroll;
}
.panel-vertical-lateral{
    max-height: 450px;
    overflow-y: scroll;
}
.btn-bicci{
    padding: 10px;
    background: #7d0ee6 !important;
    display: inline-block !important;
    padding: 8px 35px 10px 35px !important;
    border-radius: 4px !important;
}

.justifcContent{
    justify-content: space-between;
}
.float-picker{
    position: absolute;
    z-index: 900;
}
.bgcolorPicker{
    color: #fff;
    background: rgb(30,226,79);
    background: linear-gradient(90deg, rgba(30,226,79,1) 2%, rgba(30,207,183,1) 7%, rgba(131,24,190,1) 100%);
}
.select-standar{
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
    width: 100%;
}
#custom-selectFrom-comuna {
    background-color: #1e2158;
    margin-left: 0px;
    color: #fff;
    padding: 7px;
    font-size: 13px;
    border-radius: 5px;
    width: 100%;
    line-height: 2;
    display: block;
}
.ajusted{
    padding: 15px;
    border: solid 1px grey;
}