.popup {  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    padding: 15px;
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0,0,0, 0.5);  
  }  
  .popup_open {  
    position: absolute;  
    padding: 15px;
    left: 25%;  
    right: 25%;  
    top: 25%;  
    bottom: 25%;  
    margin: auto;  
    border-radius: 20px;  
    background: white;  
  }
  .closePopup{
    top: 0px;
    right: 0px;
    position: absolute;
    margin: 6px;
    background: red;
  }
  .popup_open_title{
    padding: 5px;
    color: #080808;
  }
  .carnetRider, .carnetRiderNofont {
    width: 250px;
    background-image: url('https://riders.bicci.cl/static/media/fondo.ad67ddfb.jpg');
    background-color: #ccc;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #000;
}
.centercontent{
  text-align: center;
  background-color: hsla(0,0%,100%,.603);
  padding: 3px;
}
  
.activo {
  background: #37e240;
}
.activo, .pausado {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 2px 0 0;
  color: #7d0ee6;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  text-align: center!important;
}
.inactivo{
  background: #e23737;
}
.inactivo{
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 2px 0 0;
  color: #e2e2e2;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  text-align: center!important;
}
  
  .popcontainer{
    font-size: 15px;
    text-align: left;

  }

  .activo-small{
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 3px 3px;
    margin: 2px 0 0;
    background: #37e240;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    text-align: center!important;

  }
  .inactivo-small{
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 3px 3px;
    margin: 2px 0 0;
    color: #e2e2e2;
    background: #e23737;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
    text-align: center!important;
  }