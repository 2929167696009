#imputSearchFromOrigenRoutes {
  display: block;
  width: 100% !important;
  padding: 5px !important;
  font-size: 1em !important;
  line-height: 1.5;
  color: #030507;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}

.modalBody {
  background-color: aqua;
}
.boxstyle {
  position: "absolute" !important;
  top: 20% !important;
  right: 20% !important;
  transform: "translate(-50%, -50%)" !important;
  width: 600 !important;
  background-color: "#f8f8f8" !important;
  border: "1px solid #000" !important;
  box-shadow: 24 !important;
  padding: 20px !important;
  overflow: "visible" !important;
  margin-top: 10% !important;
  margin-bottom: 10% !important;
  margin-left: 10% !important;
  margin-right: 10% !important;
}
.imput-modal-routeMaps {
  border: 0.5 solid grey !important;
}
.comboboxList-ebiex-modal {
  padding-top: 0px;
  z-index: 300 !important;
  display: block !important;
  border: unset !important;
}

.comboboxList-ebiex-modal-items {
  z-index: 999 !important;
  background-color: #f8f8f8 !important;
  position: absolute !important;
  overflow: visible;
  color: #000;
  font-size: 12px;
}

.Buttondisable {
  padding: 10px;
  background: #b0acb4 !important;
  display: inline-block !important;
  padding: 8px 35px 10px 35px !important;
  border-radius: 4px !important;
  color: rgb(255, 255, 255);
}
