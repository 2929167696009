.bodycontent-pinpass {
  min-height: 100%;
  height: 100vh;
  bottom: 0px;
  margin-bottom: 0px;
}

.container-car-pin {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}
.title-car-pinpass {
  padding: 20px;
  text-align: center;
}
.alestpinpass {
  margin-bottom: 15px;
}
.from-imputs-pinpass {
  padding-top: 20px;
  text-align: center;
}
.form-group-button {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-group-button-container {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.form-group-imput {
  margin-bottom: 1rem;
  max-width: 75px;
  min-height: 75px;
  display: inline-block;
  text-align: initial;
}
.bigtext {
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 700;
}
.imputcontainer {
  min-height: 100px;
  font-size: 35px;
}

.imputcontainer {
  -moz-appearance: textfield;
  appearance: textfield;
}

.imputcontainer::-webkit-inner-spin-button,
.imputcontainer::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 950px) {
  .imputcontainer {
    min-height: 100px;
    font-size: 35px;
    width: 45px;
  }
}
