.colors-DevelopTests {
  background-color: rgb(88, 88, 88);
  padding: 20px;
}
.textdevelop {
  background-color: transparent;
  border: 0.1;
  border-radius: 15px;
  color: grey;
  font-size: inherit;
  height: 50px;
  width: 100%;
  outline: none;
  padding-inline-end: 44px;
  position: relative;
  text-align: left;
  appearance: menulist-button;
  background-image: none !important;
  background-color: #f2f2f2 !important;
  color: fieldtext !important;
}
