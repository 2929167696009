.productHedadre {
  background-image: url(https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-biccixpress%2Fimgbanner.jpeg?alt=media&token=93608869-2320-4c56-ad5d-61c83315befd);
  background-size: cover;
  height: 250px;
}
.pointereventsnone {
  pointer-events: none;
}
.ui-vpp-store__logo-image {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: block;
  height: auto;
  margin-left: 50px;
  top: 50px;
  margin-right: auto;
  max-height: 70px;
  max-width: 96px;
  width: auto;
}

.ui-vip-grouped-header {
  background-color: #ededed;
  display: -webkit-flex;
  display: flex;
  margin-top: 12px;
  width: 100%;
}

.ui-pdp-container__row {
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
}

.breadcrumbebiex-container {
  background-color: #ededed;
  border-radius: 4px 4px 0 0;
  line-height: 1.5;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
  text-align: center;
}

.bodycontent-prodtucto {
  display: block;
  max-width: 100% !important;

  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  top: 0px;
  min-height: 1140px;
  max-width: 1140px !important;
}
#bodycontent-prodtucto {
  display: block;
  max-width: 100% !important;

  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  top: 0px;

  max-width: 1140px !important;
}

.breadcrumbebiex {
  width: 100%;
  margin-top: -70px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px 4px 0 0 !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
}
.container-producto {
  width: 100%;
  height: 37px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  color: #1d1d1d;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%);
  min-height: 350px;
  height: fit-content;
}
.gallery-wrap .thumbs-wrap {
  text-align: center;
  margin-bottom: 20px;
}

.ui.purple.basic.button {
  margin-bottom: 3px;
  img.ui.image {
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin: 3px;
    display: inline-block;
    overflow: hidden;
  }
}

.gallery-wrap .thumbs-wrap img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  cursor: zoom-in;
}
.gallery-wrap .thumbs-wrap img:hover {
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
  cursor: zoom-in;
}

.cardarticle {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 3px;
  font-size: 14px;
  padding: 24px 16px;
  width: 100%;
}
//   .bg-containercolor{

//   }
.bg-container {
  background-color: #ededed;
}
//BOX
.ui-pdp-container-ebiex {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  border-radius: 3px;
}
.seller_header {
  vertical-align: middle;
  display: flex;
}
.ui-pdp-seller__header {
  display: flex;
}
.ui-pdp-seller__header__title {
  padding-top: 0%;
}
.logo-infoStore {
  max-width: 90px !important;
  padding: 10px;
}
