div.containerBanner{
    border-radius: 30px;
    width: 100%;

    img.ui.image{
        width: 100%;
    }
}
@media only screen and (max-width: 760px){
div.containerBanner{
margin-top: -50px;
width: 100%;
img.ui.image{
    width: 100%;
}
}
}