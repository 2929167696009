.minicartBody{
        margin: auto;
        width: 100%;
        background-color: #fff;
        border-top: 1px solid rgb(207, 207, 207);
        padding: 20px;
        height: auto;
        min-height: 138px;
        //  display: none;
}

.cart-Grid-Column{
    border-left: 1px solid rgba(0,0,0,.1);
}
.image-information{
    display: flex;
 
}
.image-minicart{
    max-width: 75px;
    border-radius: 50%;
    border: 1px solid rgb(223, 223, 223);
    background-color:  rgb(223, 223, 223);
}
#pinfloatInto{
    position: absolute;
    /* margin-right: -30px; */
    left: 76px;
    top: 60px;
}
.closedBtn{
        color: #fff;
        background-color: #dc3545;
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
}
.colorRed{
    color: #fff;;
}
.f-size16{
    font-size: 16px;
}
.image-minicart.floatIn{
    max-width: 50px;
    max-height: 50PX;
    border-radius: 50%;
    border: 1px solid rgb(223, 223, 223);
    background-color:  rgb(223, 223, 223);
}
.image-minicart.floatInto{
    margin-left: -40px;
    border: 2px solid #fff;
}
.text-h1-minicart{
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.25;
    color: #00a650;
    padding: 3px;
}
.product-cart-info{
    max-width: 250px;
    text-align: left;
    margin-left: 10px;
    margin-right: 5px;
}
.btn-cart-control{
    margin-right: 0px;
    max-width: 90px;
    bottom: 0px;
    align-self: center;
    margin-left: auto;
    .form-cart-control{
        max-width: 90px;
        padding: 1px;
        padding-top: 40px;
    }
}
.btn-min.scrollto{
    font-size: 12px!important;
    padding: 7px!important;
    border-top-left-radius:50px;
    border-bottom-left-radius:50px;
    font-weight: 600;
    color: #fff;
    background-color: #8042b2;
    max-width: 20px;
    height: 30px;
}
#cartImputId{
    width: 22px;
    padding: auto;
    height: 30px;
    border-left: none;
    border-right: none;
    text-align: center;

}
.btn-plus.scrollto{
    font-size: 12px!important;
    padding: 7px!important;
    border-top-right-radius:50px;
    border-bottom-right-radius:50px;
    font-weight: 600;
    color: #fff;
    background-color: #8042b2;
    max-width: 20px;
    height: 30px;
}

.text-p-minicart{
    padding: 3px;
}
.text-p-minicart-cant{
    padding: 3px 0px 3px 3px;
}
.text-p-minicart-amount{
    padding: 0px 0px 3px 3px;
    font-weight: 600;
}
.text-p-minicart-notice{
    padding: 0px 3px 3px 3px;
    font-weight: 400;
    color:#00a650;
}
.minicartBodyHiddend{
    display: none;
}
#cartproduct{
    padding: 1px!important;
    border-radius: 0px!important;
    height: auto;
}

.btn-cart-closed{
    width: 100%;
    text-align: right;
    //position: absolute;
    padding-right: 1em!Important;
    z-index: 30;
}
.CardContentminicard{
    padding: 1px!important;

}
.CardContentminicardItents{
    padding: 1px!important;
    background-color: #fff;
}
.btn-wrap-Minicart {
    margin: auto!important;
    padding: 20px 15px!important;
    text-align: center!important;
    justify-content: center;
}
.elements-mini-cart{
//background-color: aqua;
}
.boxcart{
    padding: 5px;
   // background-color: rgb(241, 241, 241);
    height: 5em;
}
.cart-Grid-Row{
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    padding-top: 0px!important;
}

.btn-buy-minicart{
    max-width: 150px;
    padding: 10px!important;
}
.btn-buy-minicart:hover{
    fill: #fff!important;
    color: #fff!important;
    background-color: #00cdf6!important;
}

