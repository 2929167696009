#GridRowcontainer {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0.5em;
  bottom: 0px;
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  padding: 0px;
  position: relative;
}

#GridRow-external {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
  margin-bottom: 0px !important;
  height: 100% !important;
  min-height: 90vh;
  background-color: rgb(228, 228, 228);
}
.GridColumnExternal {
  padding: 0px !important;
  padding-top: 0.5em;
}
.letfbarnav {
  margin-left: 0px;
  margin-right: 0px;
  width: 25%;
  top: 4.8em;
  padding-bottom: 10%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
}

.rigthbarnav {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  height: 100%;
}
#mapControl3 {
  height: 90vh !important;
  min-width: 100% !important;
  width: 100vh !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  position: relative !important;
  overflow: hidden !important;
}

@media only screen and (max-width: 890px) {
  .letfbarnav {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    overflow-y: unset;
    position: relative;
  }
}
