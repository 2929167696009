.carousel-container {
  max-height: 300px;
  background-color: #fff;
}
.carousel-container {
  max-height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: #fff;
  color: #fff;
}
.carousel-container::-webkit-scrollbar {
  width: 0.1px;
  background-color: #fff;
  color: #fff;
}

.carousel-container::-webkit-scrollbar-track {
  background-color: #fff;
  color: #fff;
}

.carousel-container::-webkit-scrollbar-thumb {
  background-color: #fff;
  color: #fff;
}
.carousel-item {
  width: 100px !important;
  display: inline-block !important;
}
.slick-track {
  width: 1200px !important;
  height: 110px !important;
  background-color: #fff;
}

.componente2 .slick-slide {
  display: inline-block !important;
  width: 70px !important;
  height: 200px !important;
  overflow: hidden;
}

.ButtonMenu {
  align-items: center;
  justify-content: center;
  background-color: #1e2158;
  border: solid #1e2158 2px;
  border-radius: 4px;
  margin: 10px;
  -webkit-box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 1px solid #ccc;
  text-align: center;
  vertical-align: top;
}

.ButtonMenu-icon {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #ededed;
}

.ButtonMenu-title {
  margin-top: 20px;
  font-size: 9px;
  font-weight: 300;
  color: #1e2158;
}

@media only screen and (max-width: 950px) {
  .carousel-container {
    max-height: 300px;
    background-color: #fff;
  }
  .carousel-container {
    max-height: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: #fff;
    color: #fff;
  }
  .carousel-container::-webkit-scrollbar {
    width: 0.1px;
    background-color: #fff;
    color: #fff;
  }

  .carousel-container::-webkit-scrollbar-track {
    background-color: #fff;
    color: #fff;
  }

  .carousel-container::-webkit-scrollbar-thumb {
    background-color: #fff;
    color: #fff;
  }
  .carousel-item {
    width: 100px !important;
    display: inline-block !important;
  }
  .slick-track {
    width: 1200px !important;
    height: 110px !important;
    background-color: #fff;
  }
  .componente2 .slick-slide {
    display: inline-block !important;
    width: 70px !important;
    height: 200px !important;
    overflow: hidden;
  }

  .ButtonMenu {
    align-items: center;
    justify-content: center;
    background-color: #1e2158;
    border: solid #1e2158 2px;
    border-radius: 4px;
    margin: 10px;
    -webkit-box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 12px 5px rgba(0, 0, 0, 0.1);
    display: inline-block;
    width: 45px;
    height: 45px;
    border: 1px solid #ccc;
    text-align: center;
    vertical-align: top;
  }

  .ButtonMenu-icon {
    margin-top: 5px;
    margin-bottom: 5px;
    color: #ededed;
  }

  .ButtonMenu-title {
    margin-top: 20px;
    font-size: 9px;
    font-weight: 300;
    color: #1e2158;
  }
}

// .item {
//   width: 30px !important;
//   height: 30px !important;
//   display: inline-block;
//   border: solid 1px #2801d8;
//   padding: 5px;
//   border-radius: 15px;
//   margin: 2px;
// }

// .item.item-content {
//   background: #5f9ea0;
//   color: #fff;
//   font-size: 36px;
//   line-height: 100px;
//   margin: 1px;
//   padding: 1%;
//   position: relative;
//   text-align: center;
// }
