.displayFlex{
    display: flex;
}
.displayGrid{
    display: grid;
}
#custom-selectFrom1{
    background-color: #1e2158;
    margin-left: 6px;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    width: 80%;
    line-height: 2;
        label{
            color: #fff;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
#custom-selectFrom2{
        background-color: #1e2158;
        margin-left: 6px;
        color: #fff;
        padding: 10px;
        font-size: 20px;
        border-radius: 5px;
        width: 80%;
        line-height: 2;
            label{
                color: #fff;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }