.body {
  font-size: 14px;
}
.bodycontent-prodtucto-check {
  padding-top: 5%;
}
.container-producto-check {
  padding-top: 5%;
  padding-bottom: 10%;
}
.section-title-non {
  text-align: center;
}
.section-title-non h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  margin-top: 2px;
  background: #fdf7e7;
  color: #7d0ee6;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  text-align: center !important;
}
.card-body-checout {
  background-color: #eee;
}
.col-md-5-checout {
  background-color: #eee;
}

.cardarticle-checout {
  background-color: #f8f8f8;
}
.ui-pdp-container-ebiex-checout {
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 0px;
}
.checkoutImages {
  max-width: 100px !important;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #bfbfbf;
}

.btn-ebiex-froms {
  background-color: #591e8f;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin-top: 30px;
  outline: none;
  padding: 5px;
  min-width: 50px;
  font-weight: 400;
  margin: 0em 1em 0em 0em;
}
.btn-ebiex:hover {
  background-color: #25b2f4 !important;
  color: #591e8f;
}

.btn-ebiex-froms:hover {
  background-color: #25b2f4 !important;
  color: #591e8f;
}
.color-box {
  color: #000e15;
}
#imputSearchFromTo2 {
  display: block;
  width: 100% !important;
  /* padding: 20PX !important; */
  font-size: 1em !important;
  line-height: 1.5;
  color: #030507;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #b5b5b5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.formcontent-checkout {
  background-color: #f8f8f8;
  padding: 20px;
  min-height: 350px;
}
.developerControlBox {
  padding: 20px;
}
// Revision
.ui-pdp--sticky-wrapper {
  margin-bottom: 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 133px;
}
.row-tab-checkout {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.row-tab-checkout-display-block {
  display: inline-flex;
  width: 100%;
  display: block;
  padding: 10px !important;
}
.row-tab-checkout-right {
  display: inline-flex;
  width: 100%;
  text-align: initial;
}

.btn-ebiex-greem {
  background-color: #1e8f24;
  width: 100%;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  outline: none;
  padding: 10px;
  min-width: 150px;
  margin: 0 0 1em;
}
.btn-ebiex-btn-sm {
  background-color: #591e8f;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  padding: 5px;
  margin: 5px;
}

.btn-ebiex-greem:hover {
  background-color: rgb(0, 205, 246);
  color: #fff;
}

.btn-globalhover:hover {
  background-color: rgb(0, 205, 246);
  color: #fff;
}
.btn-rigth {
  text-align: right;
}
.title-h3 {
  font-size: 18px;
  font-weight: 500;
}

.box-sussces {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 18px;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}
.microAlert {
  border-radius: 15px;
  margin-right: 20px;
}
.span-float-rigth {
  float: right;
}
.resalt {
  font-weight: 600;
  color: #3625f4;
  text-decoration: underline;
  cursor: pointer;
}
