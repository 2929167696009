.typecardminadress{
    margin: 2px;
    padding: 2px;
}
.adressminicontainer{
    padding: 2px;
}
.btn-minicard{
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-minicard{
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#micuentafrominidash1{
    max-height: 150px!important;
    overflow-y: scroll;
    background-color: aliceblue;
    padding: 10px;
}

#micuentafrominidash2{
    max-height: 150px!important;
    overflow-y: scroll;
    background-color: aliceblue;
    padding: 10px;

}

#micuentafrominidash3{
    max-height: 150px!important;
    overflow-y: scroll;
    background-color: aliceblue;
    padding: 10px;
}
