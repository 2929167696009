$primary:#591E8F;
$secundary:#fff;
$calipso:#19C1B6;
$blanco:#fff;

$bgr-primary: #fff;
$bgr-secundary: #591E8F;
$bgr-grey-ligth:#d4d4d4;
$bgr-grey:#444444;

$font-bicci-morado:#591E8F;
$font-bicci-calipso:#19C1B6;
$font-bicci-grey:#7A7A7A;
$font-bicci-darkMode:#fff;

$border-light:#fff;
$border-grey-ligth:#a7a7a7;
$border-grey:#444444;

$success:#69af00;