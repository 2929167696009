img.ui.image.botonImage {
  border-radius: 100%;
  border: solid;
  text-align: center;
  width: 200px;
  max-width: 200px;
  height: 200px;
}
.textAlingCenter {
  text-align: center;
  text-align: -webkit-center;
  text-align: -Moz-center;
}
